import React, { useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./betslip.css";
import toastr from "toastr";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setBets } from "../../store/Betslip/actions";
import { Twitter, Facebook, Whatsapp, Telegram } from 'react-social-sharing';

import {
  getRegulatoryAndParameters,
  placeBetCall,
  getUserInfo, bookBetCall, axiosApi, getCashBalanceLogs
} from "../../helpers/repository";
import startCase from "lodash/startCase";
import { getUserBalance } from "../../components/Common/Utils/helpers";
import {currency} from "../../helpers/constants";
import _ from "lodash";
import ReactModal from "react-modal";
import Iframe from "react-iframe";
import {showMessage} from "../../components/Common/Toaster";

function BetSlip(props) {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  let dispatch = useDispatch();
  let history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const booking_code = query.get('booking_code')

  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const [totalOdds, setTotalOdds] = useState(0);
  const [stake, setStake] = useState(0);
  const [oldStake, setOldStake] = useState(0);
  const [exciseTaxPercentage, setExciseTaxPercentage] = useState(null);
  const [exciseTax, setExciseTax] = useState(0);
  const [WHTPercentage, setWHTPercentage] = useState(null);
  const [bonusPercentage, setBonusPercentage] = useState(null);
  const [WHT, setWHT] = useState(0);
  const [WIN, setWIN] = useState(0);
  const [minimumBet, setMinimumBet] = useState(null);
  const [maximumBet, setMaximumBet] = useState(null);
  const [maximumPayout, setMaximumPayout] = useState(null);
  const [maximumBonusPayout, setMaximumBonusPayout] = useState(null);
  const [minimumOdds, setMinimumOdds] = useState(null);
  const [bonusMinimumOdds, setBonusMinimumOdds] = useState(null);
  const [notApplicableOdds, setNotApplicableOdds] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [firstDeposit, setFirstDeposit] = useState(0);
  const [bonusAmount, setBonusAmount] = useState(0);
  const [useBonus, setUseBonus] = useState(false);
  const [canUseBonus, setCanUseBonus] = useState(false);
  const [minMatch, setMinMatch] = useState(1);
  const [isBonusActive, setIsBonusActive] = useState(0);
  const [isBalanceLess, setIsBalanceLess] = useState(false);
  const [odds, setOdds] = useState(null);
  const [totalOddNew, setTotalOddNew] = useState(0);
  const [multiBonusPercent, setMultiBonusPercent] = useState(0);
  const [multiBonusAmount, setMultiBonusAmount] = useState(0);

  const [keepBet, setKeepBet] = useState(false);
  const [acceptOddsChange, setAcceptOddsChange] = useState(false);
  const [addMoreCount, setAddMoreCount] = useState(null);
  const [bonusPercent, setBonusPercent] = useState(null);
  const [selectedPercent, setSelectedPercent] = useState(0);
  const [selectedBonus, setSelectedBonus] = useState(0);
  const [checkLiveSelected, setcheckLiveSelected] = useState(true);
  const [loading, setLoading] = useState(null);
  const [loadingBook, setloadingBook] = useState(null);
  const [isAllPrematch, setIsAllPrematch] = useState(false);
  const [openModel, setModelOpen] = useState(false);
  const [openBookModel, setBookModelOpen] = useState(false);
  const [cashLogStatus, setCashLogStatus] = useState(false);
  const [bookingId, setBookingId] = useState(booking_code);
  const [note, setNote] = useState('');
  const [searchBookingCode, setSearchBookingCode] = useState(booking_code);

  const copied = () => {
    navigator.clipboard.writeText("https://demo.betstamp.site/betSlip?booking_code="+bookingId)
    $('.apply-p1').empty().show().text("Copied").css({'color':'white','background-color':'#272727','text-align':'center','border-radius': '2px','width': 'max-content',
      'position': 'absolute','z-index': '1','padding': '8px 9px 8px 12px','margin': '-9px 0px 0px 5px','fontSize': '10px'}).delay(2000).fadeOut(300);
  }
  const CountDown = ({ hours = 0, minutes = 0, seconds = 0 }) => {

    const [over, setOver] = useState(false);
    const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

    const tick = () => {
      if (over) return;
      if (h === 0 && m === 0 && s === 0) {
        setOver(true);
        setModelOpen(false);
        setLoading(0);
        setloadingBook(0);
      }
      else if (m === 0 && s === 0) {
        setTime([h - 1, 59, 59]);
      } else if (s == 0) {
        setTime([h, m - 1, 59]);
      } else {
        setTime([h, m, s - 1]);
      }
    };

    useEffect(() => {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    });


    return (
        <div style={{ 'text-align': "center", 'margin-top' : '10px' }}>
          <h6 style={{ color: "#ff0000" }}>{`${h.toString().padStart(2, '0')}:${m
              .toString()
              .padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</h6>
        </div>
    );
  };

  useEffect(() => {
    let BetOdd = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      return sendBet;
    });
    const bookDetail = JSON.parse(localStorage.getItem("book_bet_detail"));
      if(BetOdd?.length == bookDetail?.odds?.length){
        const obj1Ids = BetOdd.map(item => item._id);
        const obj2Ids = bookDetail?.odds.map(item => item._id);
        const areAllIdsSame = obj1Ids.every(id => obj2Ids.includes(id)) && obj2Ids.every(id => obj1Ids.includes(id));
        if(areAllIdsSame){
          setSearchBookingCode(bookDetail?.booking_id)
          setBookingId(bookDetail?.booking_id)
        }
      }
      else{
        setSearchBookingCode(null)
        setBookingId(null)
      }
  },[Bets]);

  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
    getCashBalanceLogs(handleEndUserLogSuccess);
    if(isLoggedIn) {
      getUserInfo(handleSuccessGotUser, errorUserInfoCall);
      setUserBalance(getUserBalance());
    }

  }, []);

  const errorUserInfoCall = (e) => {
    showMessage(e);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  const customBetStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      backgroundColor: "#272727",
      color: "white",
      padding: "0px"
    },
  };
  const _handleSubmitBet = () => {
    setLoading(1)
    if (!isLoggedIn) {
      history.push(`/login`);
      return;
    }
    if (parseFloat(stake) < minimumBet) {
      toastr.error(`Stake should be Greater Or Equal to ${minimumBet}`);
      return;
    } else if (parseFloat(stake) > maximumBet) {
      toastr.error(`Stake should be Less Or Equal to ${maximumBet}`);
      return;
    }
    var live_array=[];
    let odds = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      sendBet.value = parseFloat(bet.value);
      sendBet.base_line = bet.special_bet_value;
      if (typeof bet.is_live === "undefined") {
        live_array.push(null)
      }
      else{
        live_array.push(bet.is_live)
      }
      return sendBet;
    });

    const allEqual = arr => arr.every(val => val === arr[0]);
    if(!allEqual(live_array)){
      toastr.error(`All odds are either of live match or upcoming match`);
      return;
    }
    var toWin=_getPossibleWin();
    if(useBonus && parseFloat(toWin)>parseFloat(maximumBonusPayout)){
      toastr.error(`Win Amount Must be less than ${maximumBonusPayout},please remove some of matches`);
      return;
    }
    else if(parseFloat(toWin)>parseFloat(maximumPayout)){
      toastr.error(`Win Amount Must be less than ${maximumPayout},please remove some of matches`);
      return;
    }
    if(parseFloat(toWin) <= 0) {
      toastr.error(`To Win should be greater than zero`);
      return;
    }
    if(parseFloat(stake) <= 0) {
      toastr.error(`Stake should be greater than zero`);
      return;
    }

    let data = {};
    data.odds = odds;
    data.stake = stake;
    data.accept_odds_change = acceptOddsChange;
    data.is_live = (live_array[0]==null) ? '0' : '1';
    data.bonus_amount = (selectedBonus != 0 && !useBonus) ? selectedBonus : null;
    data.wht = _getPossibleWHT();
    data.to_win = _getPossibleWin();
    data.use_bonus = useBonus;
    data.percentageCurrent = selectedPercent;
    data.is_balance_less = isBalanceLess;
    data.minimum_odds = minimumOdds;
    data.booking_code = bookingId;
    // data.
    placeBetCall(data, handleSuccessPlaceBet, handleErrorPlaceBet);
  };

  const _handleBookBet = () => {
    setloadingBook(1)
    if (parseFloat(stake) < minimumBet) {
      toastr.error(`Stake should be Greater Or Equal to ${minimumBet}`);
      return;
    } else if (parseFloat(stake) > maximumBet) {
      toastr.error(`Stake should be Less Or Equal to ${maximumBet}`);
      return;
    }
    var live_array=[];
    let odds = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      sendBet.value = parseFloat(bet.value);
      sendBet.base_line = bet.special_bet_value;
      if (typeof bet.is_live === "undefined") {
        live_array.push(null)
      }
      else{
        live_array.push(bet.is_live)
      }
      return sendBet;
    });

    const allEqual = arr => arr.every(val => val === arr[0]);
    if(!allEqual(live_array)){
      toastr.error(`All odds are either of live match or upcoming match`);
      return;
    }
    var toWin=_getPossibleWin();
    if(useBonus && parseFloat(toWin)>parseFloat(maximumBonusPayout)){
      toastr.error(`Win Amount Must be less than ${maximumBonusPayout},please remove some of matches`);
      return;
    }
    else if(parseFloat(toWin)>parseFloat(maximumPayout)){
      toastr.error(`Win Amount Must be less than ${maximumPayout},please remove some of matches`);
      return;
    }
    if(parseFloat(toWin) <= 0) {
      toastr.error(`To Win should be greater than zero`);
      return;
    }
    if(parseFloat(stake) <= 0) {
      toastr.error(`Stake should be greater than zero`);
      return;
    }

    let data = {};
    data.odds = odds;
    data.stake = stake;
    data.accept_odds_change = acceptOddsChange;
    data.is_live = (live_array[0]==null) ? '0' : '1';
    data.bonus_amount = (selectedBonus != 0 && !useBonus) ? selectedBonus : null;
    data.wht = _getPossibleWHT();
    data.to_win = _getPossibleWin();
    data.use_bonus = useBonus;
    data.percentageCurrent = selectedPercent;
    data.is_balance_less = isBalanceLess;
    data.minimum_odds = minimumOdds;

    // data.
    bookBetCall(data, handleSuccessBookBet, handleErrorBookBet);
  };

  const _submitBet = () => {
    setLoading(1)
    let data = JSON.parse(localStorage.getItem("store_data"));
    localStorage.removeItem("store_data");//for reducing multiple call
    placeBetCall(data, handleSuccessPlaceBet, handleErrorPlaceBet);
  }
  const handleSuccessBookBet = (response) => {
    let res = response.data.data;
    setNote('');
    setModelOpen(false);

    if(res?.booking_id){
      let bookDetail = {}
      bookDetail.booking_id = res?.booking_id;
      bookDetail.odds = res?.odds;
      localStorage.setItem("book_bet_detail",JSON.stringify(bookDetail));
    }

    if(res.is_difference) {

      let size = Object.keys(res.odds).length;

      if(!res.is_bonus_used && useBonus) {
        setNote("Required: Min. selection "+minMatch+"  with "+bonusMinimumOdds+"+ odds each");
      } else if(res.bonus_amount && (parseFloat(res.bonus_amount) != parseFloat(selectedBonus))) {
        setNote("Required: Selection  with "+minimumOdds+"+ odds");
      } else  if(size < Bets.length) {
        setNote('Some matches or markets have closed');
      }

      setOdds(res.odds)
      setTotalOddNew(res.total_odds);
      setStake(res.stake);
      setWHT(res.wht);
      setMultiBonusPercent(res.percentageCurrent);
      setMultiBonusAmount((res.bonus_amount) ? res.bonus_amount : 0);
      setWIN(res.to_win);



      let data = {};
      data.odds = res.odds;
      data.stake = res.stake;
      data.accept_odds_change = res.accept_odds_change;
      data.is_live = res.is_live;
      data.bonus_amount = res.bonus_amount;
      data.wht = res.wht;
      data.to_win = res.to_win;
      data.use_bonus = (res.is_bonus_used) ? true : false;
      data.percentageCurrent = res.percentageCurrent;
      data.is_balance_less = res.is_balance_less;
      data.minimum_odds = res.minimum_odds;

    } else {

      if (!keepBet) {
        dispatch(setBets([]));
      }
      setCanUseBonus(false);
      setUseBonus(false);
      setBonusAmount(0);//once they use bonus whole bonus balance will be used
      setloadingBook(0)
      setBookModelOpen(true)
      setBookingId(res?.booking_id);
      toastr.success(`Bet Book Successfully`);
    }
  };
  const handleSuccessPlaceBet = (response) => {
    let res = response.data.data;
    setNote('');
    setModelOpen(false);
    if(response?.data?.error_code=="selection"){
      res?.map((oddsData) => {
        $('[data-id="'+oddsData+'"]').addClass('highlightRemoveClass');
      })
      toastr.error("These markets are close ,please remove")
      return;
    }

    if(res.is_difference) {

      let size = Object.keys(res.odds).length;

      if(!res.is_bonus_used && useBonus) {
        setNote("Required: Min. selection "+minMatch+"  with "+bonusMinimumOdds+"+ odds each");
      } else if(res.bonus_amount && (parseFloat(res.bonus_amount) != parseFloat(selectedBonus))) {
        setNote("Required: Selection  with "+minimumOdds+"+ odds");
      } else  if(size < Bets.length) {
        setNote('Some matches or markets have closed');
      }

      setOdds(res.odds)
      setTotalOddNew(res.total_odds);
      setStake(res.stake);
      setWHT(res.wht);
      setMultiBonusPercent(res.percentageCurrent);
      setMultiBonusAmount((res.bonus_amount) ? res.bonus_amount : 0);
      setWIN(res.to_win);

      localStorage.removeItem("store_data");

      let data = {};
      data.odds = res.odds;
      data.stake = res.stake;
      data.accept_odds_change = res.accept_odds_change;
      data.is_live = res.is_live;
      data.bonus_amount = res.bonus_amount;
      data.wht = res.wht;
      data.to_win = res.to_win;
      data.use_bonus = (res.is_bonus_used) ? true : false;
      data.percentageCurrent = res.percentageCurrent;
      data.is_balance_less = res.is_balance_less;
      data.minimum_odds = res.minimum_odds;

      localStorage.setItem("store_data", JSON.stringify(data));
      setModelOpen(true);

    } else {

      if (!keepBet) {
        dispatch(setBets([]));
      }
      setCanUseBonus(false);
      setUseBonus(false);
      setBonusAmount(0);//once they use bonus whole bonus balance will be used
      setLoading(0)
      toastr.success(`Bet Placed Successfully`);
    }
    localStorage.removeItem("book_bet_detail");
  };
  const handleErrorPlaceBet = (error) => {
    setNote('');
    setModelOpen(false);
    setLoading(0)
    toastr.error(error);
  };

  const handleErrorBookBet = (error) => {
    setNote('');
    setModelOpen(false);
    setloadingBook(0)
    toastr.error(error);
  };
  const handleSuccessGotUser = (res) => {
    setFirstDeposit(res?.data?.first_deposit);
    setBonusAmount(res?.data?.bonus_balance);
  };

  const handleEndUserLogSuccess = (res) => {

    setCashLogStatus(res?.status);
  };

  const handleSuccess = (res) => {

    setMinimumOdds(res?.multi_bonus?.minimum_odds);
    setIsBonusActive(res?.betting_limits?.is_bonus_active);
    setBonusPercentage(res.multi_bonus?.percentages);
    setMinMatch(res?.betting_limits?.minimum_matches);
    setBonusMinimumOdds(res?.betting_limits?.minimum_odds);
    setStake(parseFloat(res.betting_limits.minimum_bet));
    setMinimumBet(parseFloat(res.betting_limits.minimum_bet));
    setMaximumBet(parseFloat(res.betting_limits.maximum_bet));
    setExciseTaxPercentage(parseFloat(res.regulatory.sales_excise_tax));
    setWHTPercentage(parseFloat(res.regulatory.wht_winning_tax));
    setMaximumPayout(res.betting_limits.maximum_payout);
    setMaximumBonusPayout(res.betting_limits.maximum_bonus_payout);
  };
  useEffect(() => {
    if(searchBookingCode!='' && searchBookingCode!=null){
      axiosApi({
        method: "get",
        url: `/client/get-booked-bet/${searchBookingCode}`,
      })
          .then(function (response) {
            if(Array.isArray(response?.data?.data)){

              dispatch(setBets(response?.data?.data));
              const betDetails = response?.data?.data;

              let BetOdd = betDetails.map((bet) => {
                return _getPlacedBet(bet.fixture_markets);
              }).map((bet) => {
                let sendBet = {};
                sendBet._id = bet._id;
                return sendBet;
              });

              let bookDetail = {}
              bookDetail.booking_id = searchBookingCode;
              bookDetail.odds = BetOdd;
              localStorage.setItem("book_bet_detail",JSON.stringify(bookDetail));
            }
            else{
              showMessage("error", response?.data?.data);
            }
          })
          .catch(function (response) {
          })
    }
  },[searchBookingCode]);

  useEffect(() => {
    if (Bets.length) {

      let allOdds = Bets?.map((b) =>
          parseFloat(_getPlacedBet(b.fixture_markets).value).toFixed(2)
      );
      let is_live = Bets.map((b) =>
          _getPlacedBet(b.fixture_markets).is_live
      );
      const allEqual = arr => arr.every(val => val == arr[0]);
      const allEqualPrematch = arr => arr.every(val => (val == null) || (val == false));

      setIsAllPrematch(allEqualPrematch(is_live));

      if(!allEqual(is_live)){
        setcheckLiveSelected(false)
      }
      let tempTotalOdds = 1;
      allOdds.forEach((ao) => {
        tempTotalOdds = tempTotalOdds * ao;
      });
      setTotalOdds(tempTotalOdds.toFixed(2));
    } else {
      setTotalOdds(0);
    }
  }, [Bets]);

  useEffect(() => {

    if(bonusPercentage && Bets.length && isAllPrematch==true && !useBonus){
      var betCount = Bets.length;

      let not_applicable_odds = 0;
      let allOdds = Bets.map((b) =>
          parseFloat(_getPlacedBet(b.fixture_markets).value).toFixed(2)
      );

      if(minimumOdds) {
        allOdds.forEach((ao) => {
          if(parseFloat(ao) < parseFloat(minimumOdds)) {
            not_applicable_odds++;
          }
        });
        setNotApplicableOdds(not_applicable_odds);
      }

      var bonus_bet_count = parseFloat(betCount) - parseFloat(not_applicable_odds);
      // percentage
      let percentages = JSON.parse(bonusPercentage)

      if(parseFloat(bonus_bet_count) == 0) {
        setSelectedPercent(0)
      } else {
        let selected = Object.values(percentages).find(e => e.selection === bonus_bet_count);
        if(!selected){
          selected = percentages[percentages.length - 1];
        }
        setSelectedPercent(selected.value)
      }


      let currentUser
      let groupedMessages = []

      for (const selection of percentages) {
        if (selection.value !== currentUser) {
          groupedMessages.push([])
          currentUser = selection.value
        }
        groupedMessages[groupedMessages.length - 1].push(selection)
      }
      let pills = []
      groupedMessages.forEach(gp => {
        let from = gp[0].selection
        let to = gp[gp.length - 1].selection
        if (![undefined, null, ""].includes(gp[0].value) && from !== to) {
          pills.push({ from: from, to: to, value: gp[0].value })
        } else if (
            ![undefined, null, ""].includes(gp[0].value) &&
            from === to
        ) {
          pills.push({ from: from, value: gp[0].value })
        }
      })
      let does_go = 0;
      pills.map((item,index) => {
        if(item?.to){
          if(item.from <= bonus_bet_count && item.to >= bonus_bet_count){
            does_go = 1;
            if(typeof(pills[index+1]) != 'undefined' && pills[index+1] !== null){
              var nextRange=pills[index+1];
              setAddMoreCount(nextRange.from - bonus_bet_count)
              setBonusPercent(nextRange.value)
            }
          }
        }
      })

      if(does_go == 0) {
        var nextRange=pills[1];
        setAddMoreCount(nextRange.from - bonus_bet_count)
        setBonusPercent(nextRange.value)
      }
      if(pills[pills.length - 1]?.from <= bonus_bet_count){
        setAddMoreCount(null)
        setBonusPercent(null)
      }
      if(pills[pills.length - 1]?.to <= bonus_bet_count){
        setAddMoreCount(null)
        setBonusPercent(null)
      }

    } else {

      setAddMoreCount(null)
      setBonusPercent(null)
      setSelectedPercent(0)
      setNotApplicableOdds(0)
    }

  },[bonusPercentage,Bets,useBonus,isAllPrematch])

  const _getPlacedBet = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return odds[0];
      }
    }
  };

  const _getMarketName = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return markets[i].market.name;
      }
    }
  };
  const _getPossibleWin = () => {
    let stakeAfterTax = stake - exciseTax;
    var wht =parseFloat(_getPossibleWHT());
    return ((((stakeAfterTax * parseFloat(totalOdds)) + parseFloat(selectedBonus)) - wht)).toFixed(2);
  };

  const _getPossibleWHT = () => {
    let stakeAfterTax = stake - exciseTax;
    let applyTax = (stakeAfterTax * totalOdds) + parseFloat(selectedBonus);

    let applyPercentWHTOnStakeAfterTax = ((applyTax-stakeAfterTax) / 100) * WHTPercentage;
    return applyPercentWHTOnStakeAfterTax.toFixed(2);
  }

  const handleCheckClick = (e) => {

    if(canUseBonus) {
      if(e) {
        setUseBonus(true);
        setOldStake(stake);
        setStake(bonusAmount);
      } else {
        setUseBonus(false);
        setStake(oldStake);
      }
    }
  }

  useEffect(() => {
    setExciseTax((stake / (100 + exciseTaxPercentage)) * exciseTaxPercentage);
  }, [stake, exciseTaxPercentage]);


  useEffect(() => {

    if(Bets.length && isAllPrematch == true) {
      let allOdds = Bets.map((b) =>
          parseFloat(_getPlacedBet(b.fixture_markets).value).toFixed(2)
      );

      let applicable_bet_count = 0;

      if(bonusMinimumOdds == 0) {
        if(Bets.length >= minMatch) {
          setCanUseBonus(true);
        } else {
          setCanUseBonus(false);
        }

      } else if(bonusMinimumOdds) {
        allOdds.forEach((ao) => {
          if(parseFloat(ao) >= parseFloat(bonusMinimumOdds)) {
            applicable_bet_count++;
          }
        });

        if(applicable_bet_count == Bets.length && applicable_bet_count >= minMatch) {
          setCanUseBonus(true);
        } else {
          setCanUseBonus(false);
        }

      } else {
        setCanUseBonus(false);
      }

      if(userBalance) {
        if(parseFloat(minimumBet) > parseFloat(userBalance.replace(`${currency} `, ""))) {
          setCanUseBonus(true);
          setIsBalanceLess(true);
        }
      } else {
        if(parseFloat(minimumBet) > parseFloat(userBalance)) {
          setCanUseBonus(true);
          setIsBalanceLess(true);
        }
      }

    } else {
      setCanUseBonus(false);
    }
    setUseBonus(false);

  }, [bonusMinimumOdds, Bets, isAllPrematch,minMatch]);

  useEffect(() => {
    if(selectedPercent && selectedPercent!=0 && !useBonus){
      // #% Boost = Stake after tax * TotalOdds - 10% [display the 10% value]
      var stakeAfterTax = (stake - exciseTax).toFixed(2);
      var stakeTax = stakeAfterTax * totalOdds;
      var selectPercent = parseFloat(((stakeTax * parseFloat(selectedPercent))/100));
      setSelectedBonus(selectPercent.toFixed(2));
    }
    else{
      setSelectedBonus(0)
    }
  }, [selectedPercent,stake,useBonus,totalOdds,exciseTax]);

  // useEffect(() => {
  //   let stakeAfterTax = stake - exciseTax;
  //   var applyTax=0;
  //   if(selectedBonus && selectedBonus!=0) {
  //     applyTax = (stakeAfterTax * totalOdds - stakeAfterTax) + parseFloat(selectedBonus);
  //   }else{
  //     applyTax = stakeAfterTax * totalOdds - stakeAfterTax;
  //   }
  //   let applyPercentWHTOnStakeAfterTax = (applyTax / 100) * WHTPercentage;

  //   setWHT(applyPercentWHTOnStakeAfterTax.toFixed(2));
  // }, [stake, exciseTax, totalOdds, WHTPercentage,selectedBonus]);

  const _renderOddName = (f, odd) => {
    if (odd?.player) {
      return odd?.player?.name;
    }
    let text = odd?.market_spec?.name;
    text = text?.replaceAll("{$competitor1}", f?.competitors[0]?.name);
    text = text?.replaceAll("{$competitor2}", f?.competitors[1]?.name);
    return startCase(text);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key]['_id'] === value);
  }

  const _renderBetslips = (can_use_all = 1) => {
    return Bets.map((betslip) => {
      let marketName = _getMarketName(betslip.fixture_markets);
      let selectedOdd = _getPlacedBet(betslip.fixture_markets);
      let can_go = false;
      let make_it_red = false;
      let selectedOddValue = parseFloat(selectedOdd?.value)?.toFixed(2);

      if(can_use_all) {
        can_go = true;

      }else if(odds){
        let res = JSON.parse(localStorage.getItem("store_data"));
        if(res) {
          Object.keys(odds).forEach(key => {
            if(odds[key]['_id'] == selectedOdd._id) {
              if(((!res.is_bonus_used && useBonus) || (res.bonus_amount && (parseFloat(res.bonus_amount) != parseFloat(selectedBonus)))) && odds[key]['value'] != selectedOdd.value) {
                make_it_red = true;
              }
              can_go = true;
              selectedOddValue = parseFloat(odds[getKeyByValue(odds,selectedOdd._id)]['value'])?.toFixed(2)
            }
          });
        }
      }
      return (
          <>
            <div className="BLM-betSlipBox" id="highlightCompletedMatchBet_0" data-id={selectedOdd._id}>
              <div className="BLM-betSlipBox-content w-100 p-2">
                <div className="BLM-bet-details">
                  <div className="BLM-betSlip-outcomeOdds">
                    <div className="BLM-betSlip-outcome truncate">
                      {_renderOddName(betslip, selectedOdd)}
                      {selectedOdd?.special_bet_value
                          ? "[" + selectedOdd?.special_bet_value + "]" //Added the special_bet_value from base_line
                          : ""}
                    </div>
                    <div className="BLM-betSlip-outcome truncate" style={{color: (!can_go || make_it_red ) ? 'red' : ''}}>
                      {selectedOddValue}
                    </div>
                  </div>
                  <div className="BLM-betSlip-market">{marketName}</div>
                  <div className="BLM-betSlip-fixture">
                    {betslip?.competitors[0].name ?? "-"} vs{" "}
                    {betslip?.competitors[1]?.name ?? "-"} @{" "}
                    <span className="BLM-betSlip-fixture">
                    {new Date(
                        parseInt(betslip?.match_date.$date.$numberLong)
                    ).toLocaleString()}
                  </span>
                  </div>
                </div>
              </div>
              <div
                  className="BLM-betSlipBox-close"
                  style={{display: (!can_use_all) ? 'none' : ''}}
                  data-selectionid="316625476"
                  onClick={() => {
                    dispatch(setBets(Bets.filter((b) => b._id != betslip._id)));
                  }}
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>
          </>
      );
    });
  };

  return (
      <>
        <div className="main-body">
          <div className="w-100 bg-light h-100">
            <div className="BLM-rightMenu-main-content">
              <div className="">
                <div className="BLM-betSlip-header">
                  <div
                      className="BLM-betSlip-closeButton"
                      id="BLM-betSlip-closeButton"
                      onClick={() => {
                        props.history.push('/home');
                      }}
                  >
                    <i className="sb-icon_close"></i>
                  </div>
                  <div className="BLM-betSlip-txt">
                    <span className="">Betslip</span>
                  </div>
                  <div className="input-group input-grp-book">
                    <input type="text" id="search-booking-id" className="BLM-form-control" placeholder="Booking Code" aria-label="Booking Code" aria-describedby="basic-addon2" style={{color:"black"}} maxLength={6} value={bookingId}/>
                    <div className="input-group-append"><span
                        className="input-group-text txt-input-book text-prepend-box" onClick={()=>{
                      setSearchBookingCode(document.getElementById('search-booking-id').value)
                    }}>Search</span></div>
                  </div>
                  <div className="BLM-betSlip-header_right">
                    {isLoggedIn ? (
                        <span className="BLM-betSlip-userBalance" id="betslipUserBalance">
                    {`${userBalance}`}
                  </span>
                    ) : ""}
                    {isLoggedIn ? (
                        <Link to="/Deposits">
                          <button
                              className="btn BLM-btn BLM-btnOdds active BLM-btnMedium"
                              style={{ backgroundColor: "#00ad73" }}
                          >
                            <span className="BLM-btnTxt">Deposit</span>
                          </button>
                        </Link>
                    ) : (
                        <Link to="/login">
                          <button className="btn BLM-btn BLM-btnOdds active BLM-btnMedium">
                            <span className="BLM-btnTxt">Login</span>
                          </button>
                        </Link>
                    )}
                  </div>
                </div>

                <div
                    className="BLM-betSlip-content"
                    style={{ overflowY: "scroll" }}
                >
                  <div className="pl-2 main-slip-st">{_renderBetslips(1)}</div>

                  <div className="BLM-betSlip-acceptOddsChange-container p-2">
                    <div className="BLM-betSlip-acceptOddsChange w-75">
                      <div className="BLM-checkbox mb-2">
                        <input
                            type="checkbox"
                            id="acceptOddschange"
                            onClick={(e) => {
                              setAcceptOddsChange(!acceptOddsChange);
                            }}
                            selected={acceptOddsChange ? true : false}
                        />
                        <label htmlFor="acceptOddschange" />
                        <span className="BLM-checkboxLabel">
                        Accept Odds Change
                      </span>
                      </div>
                      <div className="BLM-checkbox mb-2">
                        <input
                            onClick={(e) => {
                              setKeepBet(!keepBet);
                            }}
                            selected={keepBet ? true : false}
                            type="checkbox"
                            id="keepPlacedBets"
                        />
                        <label htmlFor="keepPlacedBets" />
                        <span className="BLM-checkboxLabel">
                        Keep bets in betslip
                      </span>
                      </div>
                    </div>
                    <div
                        onClick={() => {
                          dispatch(setBets([]));
                        }}
                        className="BLM-betSlip-clearAll"
                    >
                      Clear All
                    </div>
                  </div>
                </div>
                {
                  <div className="row img_row">
                    <ReactModal
                        isOpen={openModel}
                        style={customStyles}

                    ><div>
                      <div>

                        <div className="modalHeader ">BetSlip Confirmation </div>
                      </div>
                      <div
                          className="BLM-betSlip-content"
                          style={{ overflowY: "auto",height: "45vh" }}
                      >
                        <div className="pl-2 main-slip-st">{_renderBetslips(0)}</div>
                      </div>
                      <div className="BLM-betSlip-footer">
                        { (note) ?
                            <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                              {" "}

                              <a href="/" className="text-danger">
                                {note}
                              </a>

                            </div> : null}
                        <div className="BLM-totalStakeOddsWins">
                          <div className="BLM-accordion" id="getBetslipAccordianId">
                            <div className="BLM-accordion-header BLM-BetslipArrowAfter">
                              <div className="BLM-totalStakeOddsWins-content">
                                <div className="BLM-contentBlock font-cls">
                                  <div className="BLM-leftContent">Total Odds:</div>
                                  <div className="BLM-rightContent">{totalOddNew}</div>
                                </div>
                                <div className="BLM-contentBlock BLM-wht font-cls">
                                  <div className="BLM-leftContent">
                                    Stake after tax:
                                  </div>
                                  <div
                                      className="BLM-rightContent"
                                      id="bindDeductedStakeValue"
                                  >
                                    {currency} {(stake - exciseTax).toFixed(2)}
                                  </div>
                                </div>
                                <div className="BLM-contentBlock BLM-wht font-cls">
                                  <div className="BLM-leftContent">Excise Tax:</div>
                                  <div
                                      className="BLM-rightContent"
                                      id="bindExciseValue"
                                  >
                                    {currency} {exciseTax.toFixed(2)}
                                  </div>
                                </div>
                                <div className="BLM-contentBlock BLM-wht font-cls">
                                  <div className="BLM-leftContent">WHT:</div>
                                  <div className="BLM-rightContent" id="bindTaxValue">
                                    {currency} {WHT}
                                  </div>
                                </div>
                                {multiBonusPercent && multiBonusPercent!=0 ?
                                    <div className="BLM-contentBlock">
                                      <div className="BLM-leftContent BLM-txtHighlight font-cls">
                                        {multiBonusPercent}% Boost:
                                      </div>
                                      <div
                                          className="BLM-rightContent BLM-txtHighlight font-cls"
                                          id="bindWinningValue"
                                      >
                                        {currency} {multiBonusAmount}
                                      </div>
                                    </div>
                                    :''}
                                <div className="BLM-contentBlock">
                                  <div className="BLM-leftContent BLM-txtHighlight font-cls">
                                    To Win:
                                  </div>
                                  <div
                                      className="BLM-rightContent BLM-txtHighlight font-cls"
                                      id="bindWinningValue"
                                  >
                                    {currency} {WIN}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <CountDown seconds={15} />
                        </div>
                        <p style={{fontSize: "10px",color: "red",marginTop: "10px"}}>By pressing “Bet Confirmation” you accept the above choices, or let timer run out to change</p>
                        <div><button className="btn BLM-btnPrimary active"  onClick={() => {
                          _submitBet();
                        }} style={{height: "35px",
                          fontSize: "12px",marginTop: "7px" }} >Bet Confirmation</button></div>

                      </div>

                    </div>

                    </ReactModal>
                  </div>
                }
                {
                  <div className="row img_row">
                    <ReactModal
                        isOpen={openBookModel}
                        style={customBetStyles}
                    >

                      <div className="modal-content  book-a-bet-div">
                        <div className="modal-header">
                          <div className="modal-title h4">SHARE BET</div>
                          <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={()=> {
                            setBookModelOpen(false)
                          }}></button>
                        </div>
                        <div className="modal-body">

                          <p style={{fontSize:"17px"}}>You can place the booked bet in a betslip or share it with a friend.</p>

                          <div className="code-div">
                            <p style={{fontSize:"17px"}}>selection saved successfully. your code is</p>
                            <h4>{bookingId}</h4>

                            <p className="copy-code"><a href="javascript:void(0)" style={{ textDecoration: "underline"}} onClick={()=> {
                              copied()
                            }}>COPY BOOKING CODE</a> <span className="apply-p1"></span></p>
                            <Whatsapp solid medium message={`Booked Ticket - Great Winnings, just visit Betstamp and enter Booking Code: ${bookingId} on Betslip.`} link="https://demo.betstamp.site" />
                            <Telegram solid medium text={`Booked Ticket - Great Winnings, just visit Betstamp and enter Booking Code: ${bookingId} on Betslip.`} link="https://demo.betstamp.site" />
                            <Facebook solid medium quote={`Booked Ticket - Great Winnings, just visit Betstamp and enter Booking Code: ${bookingId} on Betslip.`} link="https://demo.betstamp.site" />
                            <Twitter solid medium message={`Booked Ticket - Great Winnings, just visit Betstamp and enter Booking Code: ${bookingId} on Betslip.`} link="https://demo.betstamp.site" />

                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary btn-new-bet"    onClick={(e) => {
                            window.location.href=`/home`;
                          }}>NEW BET</button>
                        </div>
                      </div>
                    </ReactModal>
                  </div>
                }
                {/* --------------------------- footer --------------------------- */}
                <div className="BLM-betSlip-footer">
                  {
                    (isAllPrematch==false)?
                        <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                          {" "}

                          <a href="/" className="text-danger">
                            Please remove live matches to get Bonus
                          </a>

                        </div> :
                        (addMoreCount && bonusPercent) ? (
                            <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                              {" "}

                              <a href="/" className="text-danger">
                                Add {addMoreCount} more selections to receive a bonus of {bonusPercent}%
                              </a>
                              {(notApplicableOdds > 0) ?

                                  <button style={{background: '#ebefff', border: "none" }}  title={`All selections value should be greater than  ${minimumOdds} to receive a bonus`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                      <path
                                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                    </svg>
                                  </button> : ""}
                            </div>
                        ) : ""
                  }

                  <div className="BLM-stakeBox-container">
                    {
                      ((firstDeposit == 0 || (isBalanceLess && cashLogStatus)) && Bets.length && parseFloat(bonusAmount) > 0 && isBonusActive && isAllPrematch) ?(//*
                              <div className="BLM-form-group">
                                <div className="BLM-checkbox">
                                  <button style={{ background: '#d9e1ff', border: "none"}} title={` Required: Min. selection ${minMatch}  with ${bonusMinimumOdds}+ odds each`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="25" viewBox="0 0 137 200" fill="red">
                                      <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <path d="M63.36,0c37.1,0,61.88,18.38,61.88,44,0,17.79-10.29,30.24-28.71,39.66C78.8,92.47,73.79,98.52,73.79,110.15v6.75H42.38l-.14-8.26C41,91.43,48.88,81,67.56,71.54,84.35,62.81,90,56.53,90,45,90,32.69,78.66,23.73,61.6,23.73c-17.46,0-28.7,9.19-29.78,23.61H0C1.08,20,23.15,0,63.36,0ZM38.72,155.05c0-9.19,8.8-16.4,19.77-16.4s19.63,7.21,19.63,16.4-8.66,16.52-19.63,16.52S38.72,164.24,38.72,155.05Z"  stroke="#dc3545"/>
                                        </g>
                                      </g>
                                    </svg>
                                  </button>
                                  <input
                                      id="navSubMenuCheck1"
                                      name
                                      defaultValue="check1"
                                      type="checkbox"
                                      style={{opacity: !canUseBonus ? '.65' : '1'}}
                                      disabled={!canUseBonus}
                                      checked={useBonus ? true : false}
                                      onChange={(e) => {
                                        handleCheckClick(e.target.checked);
                                      }}
                                  />
                                  <label htmlFor="navSubMenuCheck1" style={{opacity: !canUseBonus ? '.65' : '1'}}/>
                                  <span className="BLM-checkboxLabel" style={{opacity: !canUseBonus ? '.65' : '1'}}>
                            <b>Use Bonus Amount</b>
                          </span>
                                </div>
                              </div>
                          )
                          : ""
                    }
                    <div className="BLM-freeBet" />
                    <div id="displayStakeBox">
                      <div className="BLM-stakeInputBox BLM-form-control">
                        {" "}
                        <span className="font-cls p-2">{currency}</span>
                        <input
                            type="number"
                            className="w-50"
                            placeholder="Stake"
                            id="stakeValue"
                            disabled={useBonus}
                            value={stake}
                            style={{opacity: useBonus ? '.65' : '1','pointer-events': useBonus ? 'none' : 'auto'}}
                            onChange={(e) => {
                              setStake(e.target.value);
                            }}
                        />
                        <i className="sb-icon_close BLM-closeIcon p-2" />
                      </div>
                    </div>
                    <div id="displayFreeBetDropDown" />
                  </div>
                  <div className="BLM-totalStakeOddsWins">
                    <div className="BLM-accordion" id="getBetslipAccordianId">
                      <div className="BLM-accordion-header BLM-BetslipArrowAfter">
                        <div className="BLM-totalStakeOddsWins-content">
                          <div className="BLM-contentBlock font-cls">
                            <div className="BLM-leftContent">Total Odds:</div>
                            <div className="BLM-rightContent">{totalOdds}</div>
                          </div>
                          <div className="BLM-contentBlock BLM-wht font-cls">
                            <div className="BLM-leftContent">
                              Stake after tax:
                            </div>
                            <div
                                className="BLM-rightContent"
                                id="bindDeductedStakeValue"
                            >
                              {currency} {(stake - exciseTax).toFixed(2)}
                            </div>
                          </div>
                          <div className="BLM-contentBlock BLM-wht font-cls">
                            <div className="BLM-leftContent">Excise Tax:</div>
                            <div
                                className="BLM-rightContent"
                                id="bindExciseValue"
                            >
                              {currency} {exciseTax.toFixed(2)}
                            </div>
                          </div>
                          <div className="BLM-contentBlock BLM-wht font-cls">
                            <div className="BLM-leftContent">WHT:</div>
                            <div className="BLM-rightContent" id="bindTaxValue">
                              {currency} {_getPossibleWHT()}
                            </div>
                          </div>
                          {selectedPercent && selectedPercent!=0 ?
                              <div className="BLM-contentBlock">
                                <div className="BLM-leftContent BLM-txtHighlight font-cls">
                                  {selectedPercent}% Boost:
                                </div>
                                <div
                                    className="BLM-rightContent BLM-txtHighlight font-cls"
                                    id="bindWinningValue"
                                >
                                  {currency} {selectedBonus}
                                </div>
                              </div>
                              :''}
                          <div className="BLM-contentBlock">
                            <div className="BLM-leftContent BLM-txtHighlight font-cls">
                              To Win:
                            </div>
                            <div
                                className="BLM-rightContent BLM-txtHighlight font-cls"
                                id="bindWinningValue"
                            >
                              {currency} {_getPossibleWin()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="BLM-placeBet-container p-2">
                    <div id="displayPlaceBetButton">
                      {
                        (loading!=null && (loading==1)) ?
                            <button
                                className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn BLM-btnPlaceBet"
                            >
                              <span className="spinner-grow spinner-grow-sm"></span>
                            </button>
                            :
                            <button
                                className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn BLM-btnPlaceBet"
                                onClick={() => {
                                  _handleSubmitBet();
                                }}
                            >
                              <span className="BLM-btnTxt text-light">Place Bet</span>
                            </button>
                      }
                    </div>{" "}
                    <div id="displayAcceptOddsButton" />
                  </div>
                  <div className="BLM-placeBet-container p-2">
                    <div id="displayBookBetButton">
                      {
                        (loadingBook!=null && (loadingBook==1)) ?
                            <button
                                className="btn BLM-btnSuccess active BLM-btnLarge BLM-btn BLM-btnPlaceBet"
                            >
                              <span className="spinner-grow spinner-grow-sm"></span>
                            </button>
                            :
                            <button
                                className="btn BLM-btnSuccess active BLM-btnLarge BLM-btn BLM-btnBookBet"
                                onClick={() => {
                                  _handleBookBet();
                                }}
                            >
                              <span className="BLM-btnTxt text-light">Book Bet</span>
                            </button>
                      }



                    </div>{" "}
                    <div id="displayAcceptOddsButton" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default withRouter(BetSlip);
