import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import store from "../index";
// Login Redux States
import {
  CHANGE_PIN,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  UPDATE_PROFILE,
} from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
//Include Both Helper File with needed methods
import {
  storeTokenToLocalStorage,
  deleteTokenFromLocalStorage,
  storeUserToLocalStorage,
} from "../../helpers/utils";
import {
  updateUserApiCall,
  logoutApiCall,
  postLogin,
  registerApiCall,
} from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import { setActiveFooterTab, setActiveTab } from "../actions";
import { getAllLeagues } from "../Leagues/actions";
import { getAllLocations } from "../Locations/actions";
import { getAllSports } from "../Sports/actions";
import {getAllProducts} from "../Products/actions";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      phone_number: user?.phone_number,
      password: user?.password,
    });

    let token = response.data._token;
    storeTokenToLocalStorage(token);

    storeUserToLocalStorage(response.data);
    yield put(loginSuccess(response.data));
    yield put(apiError(""));
    history.push("/home");
    showMessage("success", `welcome ${response?.data?.user?.phone_number}`);
    store.dispatch(setActiveTab("home-upcoming"));
    store.dispatch(setActiveFooterTab("home"));
    yield store.dispatch(getAllLeagues());
    yield store.dispatch(getAllLocations());
    yield store.dispatch(getAllSports());
  } catch (error) {
    yield put(apiError(error.response.data.errors));
    // history.push("/login")
  }
}

function* registerUser({ payload: { user, history }, cb }) {
  try {
    const response = yield call(registerApiCall, {
      phone_number: user?.phone_number,
      password: user?.password,
      ref_id: user?.ref_id,
      code: user?.code,
      pin: user?.pin,
      type: user?.type,
      referral_source: user?.referral_source,
    });
    cb(response.data.message);
  } catch (error) {
    yield put(apiError(error.response.data.errors));
    // history.push("/login")
  }
}

function* attemptChangePin(action) {
  const {
    payload: { password },
  } = action;
  try {
    const response = yield call(updateUserApiCall, { password: password });
    showMessage("success", response.data.message);
  } catch (error) {
    yield put(apiError(error?.response?.data?.errors));
  }
}

function* attemptUpdateProfile(action) {
  const {
    payload: { first_name, last_name, email },
  } = action;
  try {
    const response = yield call(updateUserApiCall, {
      first_name: first_name,
      last_name: last_name,
      email: email,
    });
    showMessage("success", response.data.message);
  } catch (error) {
    yield put(apiError(error?.response?.data?.errors));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logoutApiCall);
    yield put(logoutUserSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  } finally {
    deleteTokenFromLocalStorage();
    localStorage.removeItem("user");
    sessionStorage.removeItem("token");
    history.push("/login");
    yield showMessage("success", `Bye for now`);
    yield store.dispatch({
      type: "RESET",
    });
    yield store.dispatch(getAllLeagues());
    yield store.dispatch(getAllLocations());
    yield store.dispatch(getAllSports());
    yield store.dispatch(getAllProducts());
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(CHANGE_PIN, attemptChangePin);
  yield takeEvery(UPDATE_PROFILE, attemptUpdateProfile);
}

export default authSaga;
