import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab, setActiveFooterTab } from "../../store/actions";
import { withRouter } from "react-router-dom";

function Footer(props) {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const activeTab = useSelector(
    (state) => state?.CommonState?.activeTab ?? false
  );
  const activeFooterTab = useSelector(
    (state) => state?.CommonState?.activeFooterTab ?? false
  );
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();

  (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/62fdeb3e54f06e12d88f4e52/1gantts4t';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
  });

  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const dispatch = useDispatch();

  return (
    <>
      <div className="BLM-mobile-footer">
        <ul className="">
          <li
            className={`${
              ["home-upcoming"].includes(activeTab) ? "active" : ""
            }`}
            onClick={() => {
              dispatch(setActiveFooterTab("home"));
              dispatch(setActiveTab("home-upcoming"));
              props.history.push("/home");
            }}
          >
            <div className="BLM-iconSvg BLM-homeIcon">
              <img src="/assets/images/PNG/home.png" style={{
                "width": "18px",
                "margin-top": "3px"}} />
              {/*<i class="sb-icon_home"></i>*/}
            </div>
            <div>Home</div>
          </li>
          <li
            onClick={() => {
              props.history.push("/home");
              dispatch(setActiveTab("home-live-now"));
              dispatch(setActiveFooterTab("home-live-now"));
            }}
            className={`${
              ["home-live-now"].includes(activeTab) ? "active" : ""
            }`}
          >
            <div className="BLM-iconSvg BLM-inplayIcon">
              <img src="/assets/images/PNG/live.png" style={{
                "width": "18px",
                "margin-top": "3px"}} />
              {/*<i class="sb-icon_In-play"></i>*/}
            </div>
            <div>Live</div>
          </li>
          <li
            id="BLM-betSlip-Button"
            className="BLM-betSlip-active"
            onClick={(event) => {
              dispatch(setActiveFooterTab("betslip"));
              props.history.push("/betSlip");
              // window.highlightFooterTabWoLogin(
              //   event,
              //   event.target.getAttribute("data-footer-betslip")
              // );
              // window.openHiddenBetSlip();
            }}
            data-footer-betslip="Betslip"
          >
            <a href="javascript:void(0);" target>
              <div className="BLM-iconSvg BLM-betslipIcon">
                <img src="/assets/images/PNG/betslip.png" style={{
                  "width": "18px",
                  "margin-top": "3px"}} />
                {/*<svg*/}
                {/*  version="1.1"*/}
                {/*  xmlns="http://www.w3.org/2000/svg"*/}
                {/*  xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                {/*  x="0px"*/}
                {/*  y="0px"*/}
                {/*  viewBox="0 0 24 24"*/}
                {/*  style={{ enableBackground: "new 0 0 24 24" }}*/}
                {/*  xmlSpace="preserve"*/}
                {/*>*/}
                {/*  <path*/}
                {/*    d="M21.6,1.7L21.6,1.7L21.6,1.7 M12,1.9l1.5,0.8c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2l0.5-0.3c0.3-0.2,0.7-0.3,1-0.5*/}
                {/*                L18,2.6l0.1,0.1c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2l0.6-0.3v1.3v2.5v3v3.2v3.2v2.9v2.4v0.8l-0.2-0.1l-0.4-0.2*/}
                {/*                c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2L18,21.4l-0.4,0.2c-0.3,0.2-0.6,0.3-1,0.5l-1.4-0.7l-0.1-0.1*/}
                {/*                c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.9,0.2L13,21.6c-0.3,0.2-0.7,0.3-1,0.5l-0.7-0.3l-0.8-0.4c-0.3-0.1-0.5-0.2-0.8-0.2*/}
                {/*                c-0.3,0-0.6,0.1-0.8,0.2l-0.5,0.3c-0.3,0.2-0.7,0.3-1,0.5l-1.5-0.8c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2l-0.6,0.3v-1.3*/}
                {/*                v-2.5v-3v-3.2V8.4V5.5V3.2V2.4l0.1,0.1l0.5,0.3c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2l0.1,0l0.4-0.2c0.3-0.2,0.6-0.3,1-0.5*/}
                {/*                l1.1,0.6l0.4,0.2c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2L11,2.4L12,1.9 M12,0C12,0,12,0,12,0c-0.2,0-0.3,0.1-0.5,0.1*/}
                {/*                c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.4,0.2-0.8,0.4-1.2,0.6C10,0.9,9.9,1,9.7,1.1c-0.5-0.3-1-0.6-1.6-0.8C8,0.2,7.9,0.2,7.8,0.1*/}
                {/*                C7.7,0.1,7.6,0,7.4,0c0,0,0,0,0,0C7.2,0,7.1,0.1,6.9,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1C6.3,0.4,5.9,0.6,5.6,0.8*/}
                {/*                C5.4,0.9,5.2,1,5.1,1.1C4.5,0.8,4,0.6,3.5,0.3C3.4,0.2,3.3,0.2,3.2,0.1C3.1,0.1,2.9,0,2.7,0c-0.2,0-0.3,0-0.5,0.1*/}
                {/*                C2,0.3,1.8,0.6,1.8,1c0,0.2,0,0.4,0,0.6c0,0.5,0,1.1,0,1.6c0,0.8,0,1.6,0,2.4c0,1,0,1.9,0,2.9c0,1.1,0,2.1,0,3.2c0,1.1,0,2.1,0,3.2*/}
                {/*                c0,1,0,2,0,3c0,0.8,0,1.7,0,2.5c0,0.6,0,1.2,0,1.8c0,0.3,0,0.6,0,0.9c0,0,0,0,0,0c0,0.3,0.2,0.7,0.5,0.8C2.4,23.9,2.6,24,2.7,24*/}
                {/*                c0.2,0,0.3,0,0.5-0.1c0.6-0.3,1.2-0.6,1.8-1c0.5,0.3,1,0.6,1.6,0.8c0.1,0,0.2,0.1,0.3,0.1C7,23.9,7.2,24,7.3,24c0,0,0,0,0,0*/}
                {/*                c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.4-0.2,0.8-0.4,1.2-0.6c0.2-0.1,0.3-0.2,0.5-0.3c0.5,0.3,1,0.6,1.6,0.8*/}
                {/*                c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1*/}
                {/*                c0.4-0.2,0.8-0.4,1.2-0.6c0.2-0.1,0.3-0.2,0.5-0.3c0.5,0.3,1,0.6,1.6,0.8c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1*/}
                {/*                c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.4-0.2,0.8-0.4,1.2-0.6c0.2-0.1,0.3-0.2,0.5-0.3*/}
                {/*                c0.5,0.3,1,0.5,1.6,0.8c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0C21,24,21.1,24,21.2,24c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0*/}
                {/*                c0.2-0.1,0.5-0.2,0.6-0.4c0,0,0,0,0,0c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.5,0-1.1,0-1.6c0-0.8,0-1.6,0-2.4*/}
                {/*                c0-1,0-1.9,0-2.9c0-1.1,0-2.1,0-3.2c0-1.1,0-2.1,0-3.2c0-1,0-2,0-3c0-0.8,0-1.7,0-2.5c0-0.6,0-1.2,0-1.8c0-0.3,0-0.6,0-0.9*/}
                {/*                c0,0,0,0,0,0c0-0.3-0.2-0.7-0.5-0.8C21.6,0.1,21.4,0,21.3,0c-0.2,0-0.3,0-0.5,0.1c-0.6,0.3-1.2,0.6-1.8,1c-0.5-0.3-1-0.6-1.6-0.8*/}
                {/*                c-0.1,0-0.2-0.1-0.3-0.1C17,0.1,16.8,0,16.7,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1*/}
                {/*                c-0.4,0.2-0.8,0.4-1.2,0.6c-0.2,0.1-0.3,0.2-0.5,0.3c-0.5-0.3-1-0.6-1.6-0.8c-0.1,0-0.2-0.1-0.3-0.1C12.3,0.1,12.2,0,12,0L12,0z*/}
                {/*                M16.8,6H7.2C6.5,6,6,6.5,6,7.2s0.5,1.2,1.2,1.2h9.6c0.7,0,1.2-0.5,1.2-1.2S17.5,6,16.8,6L16.8,6z M16.8,10.8H7.2*/}
                {/*                C6.5,10.8,6,11.3,6,12s0.5,1.2,1.2,1.2h9.6c0.7,0,1.2-0.5,1.2-1.2S17.5,10.8,16.8,10.8L16.8,10.8z M14.6,15.6H7c-0.5,0-1,0.5-1,1.2*/}
                {/*                C6,17.5,6.4,18,7,18h7.7c0.5,0,1-0.5,1-1.2C15.6,16.1,15.2,15.6,14.6,15.6L14.6,15.6z"*/}
                {/*  />*/}
                {/*</svg>*/}
              </div>
              <div>Betslip</div>
              <span
                className="BLM-bets-count"
                id="getBetsCountDisplay"
                data-footer-betslip="Betslip"
              >
                {Bets.length}
              </span>
            </a>
          </li>
          {isLoggedIn && (
            <li
              onClick={() => {
                props.history.push("/MyBets");
                dispatch(setActiveFooterTab("my-bets"));
                dispatch(setActiveTab(""));
              }}
              className={`${
                ["my-bets"].includes(activeFooterTab) ? "active" : ""
              }`}
            >
              <div className="BLM-iconSvg BLM-inplayIcon">
                <i className="sb-icon_myBets" />
              </div>
              <div>My Bets</div>
            </li>
          )}

          <li
            id="open_fc_widget"
          >
            <a className="btn BLM-betSlip-Button" href="javascript:void(Tawk_API.toggle())" style={{"color": "white"}}>
              <div className="BLM-iconSvg BLM-chatIcon">
                <i className="sb-icon_chat" />
              </div>
            </a>
            <div id="status">Chat</div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default withRouter(Footer);
