import React, { useState, useEffect } from "react";
import FieldValidation from "../../components/Common/FieldValidation";
import PageHeader from "../../components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../store/Auth/actions";
import { axiosApi, getUserDetails } from "../../helpers/repository";
import { apiError } from "../../store/actions";
import { isEmpty } from "lodash";

import { Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";

function Profile(props) {
  const [marketing_opt_in, set_marketing_opt_in] = useState("mobile");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [nrc_number, set_nrc_number] = useState("");
  const [selected_files, set_selected_files] = useState([]);
  const [block_update, set_block_update] = useState(false);
  const [phone_number, set_phone_number] = useState("");
  const [code, set_code] = useState(null);
  const [pin, setPin] = useState(null);
  const [sendCodeMessage, setSendCodeMessage] = useState("Send Code");
  const [verify_mobile_number, set_verify_mobile_number] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  const [status, set_status] = useState(false);
  const isEmpty = (value) => value == null || value === "";

  const [email, set_email] = useState("");
  useEffect(() => {
    dispatch(apiError(""));
    getUserDetails().then((res) => {
      set_first_name(res?.data?.data?.first_name || "");
      set_last_name(res?.data?.data?.last_name || "");
      set_phone_number(res?.data?.data?.phone_number || "");
      set_email(res?.data?.data?.email || "");
      set_nrc_number(res?.data?.data?.nrc_number || "");
      set_selected_files(res?.data?.data?.id_card || []);
      set_block_update(res?.data?.data?.id_card ? true : false);
      set_status(res?.data?.data?.p_status || false);
    });
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create FormData instance
    let form_data = new FormData();

    // Append non-file data
    form_data.append("first_name", first_name);
    form_data.append("last_name", last_name);
    form_data.append("email", email);
    form_data.append("nrc_number", nrc_number);
    form_data.append("pin", pin);
    form_data.append("code", code);
    form_data.append("id_card", selected_files);

    // Append files
    // if (selected_files && selected_files.length > 0) {
    //   // for (let file of selected_files) {
    //     form_data.append("id_card", selected_files);
    //   // }
    // }

    dispatch(updateProfile(form_data));
    if (selected_files && selected_files.length > 0) {
      set_block_update(true);
    }
    set_code(null);
    setPin(null);
  };

  const formErrors = useSelector((state) => state?.CommonState);

  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };

  function handleAcceptedFiles(files) {
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file?.size),
      });
      set_selected_files(file);
    });
  }
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  const sendCode = () => {
    setSendCodeMessage("Sent");
    setRunTimer((t) => !t);
    const data = {
      phone_number: phone_number,
      type: "forgotpin",
    };

    axiosApi({
      method: "post",
      url: `/client/verify-code`,
      data: data,
    })
      .then(function (response) {
        // console.log(response?.data?.pin)

        setPin(response?.data?.pin);
        setSendCodeMessage("Send Code");
      })
      .catch(function (response) {
        set_verify_mobile_number(response?.response?.data?.error);
      });
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      <PageHeader title={"Profile"} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister BLM-login">
            <div className="BLM-content">
              <div
                className={`btn ${
                  status == 1 ? "BLM-btnSuccess" : "BLM-btnDanger"
                } active ${status == 1 ? "BLM-btnsmall" : "BLM-btnLarge"} mb-2`}
                id={
                  status == 1
                    ? "verifyAccountButtonClick"
                    : "notVerifyAccountButtonClick"
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "white",
                  }}
                >
                  {status == 1 ? (
                    <span className="BLM-btnTxt mb-2">Account Verified</span>
                  ) : status == 0 ? (
                    <span className="BLM-btnTxt mb-2">
                      Account Not Verified
                    </span>
                  ) : status == 2 ? (
                    <span className="BLM-btnTxt mb-2">
                      Your submission has been declined, contact support on live
                      chat
                    </span>
                  ) : null}
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter First Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userName"
                      required
                      onChange={(e) => {
                        removeError("phone_number");
                        set_first_name(e.target.value);
                      }}
                      value={first_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.first_name ? (
                      <FieldValidation errorMessage={formErrors?.first_name} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Last Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userLastName"
                      onChange={(e) => {
                        removeError("phone_number");
                        set_last_name(e.target.value);
                      }}
                      value={last_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.last_name ? (
                      <FieldValidation errorMessage={formErrors?.last_name} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Nrc number:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userNrcNumber"
                      placeholder="123456/01/1"
                      maxLength="11"
                      onChange={(e) => {
                        removeError("nrc_number");
                        set_nrc_number(e.target.value);
                      }}
                      required
                      value={nrc_number}
                      disabled={block_update}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.nrc_number ? (
                      <FieldValidation errorMessage={formErrors?.nrc_number} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">ID Card Upload:</div>
                  <Card>
                    <CardBody>
                      <h6 className="card-title">Dropzone</h6>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        disabled={block_update} // Disable Dropzone if files are selected
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className={`dz-message needsclick mt-2 d-flex flex-column align-items-center justify-content-center ${
                                block_update ? "disabled" : ""
                              }`} // Apply 'disabled' class based on file selection
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>
                                {selected_files != ""
                                  ? "File Added"
                                  : "Drop file here or click to upload."}
                              </h4>
                              <em style={{ fontSize: "12px", color: "red" }}>
                                {selected_files != ""
                                  ? ""
                                  : "(*.jpeg,*.png,*.jpg,*.gif will be accepted)"}
                              </em>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </CardBody>
                  </Card>
                </div>
                <div className="BLM-form-errorMsg">
                  {formErrors?.id_card?.length >= 0 ? (
                    <FieldValidation errorMessage={formErrors?.id_card[0]} />
                  ) : null}
                </div>

                <div className="BLM-form-group">
                  <div className="BLM-form-label">Email:</div>
                  <div className="BLM-form-control">
                    <input
                      type="email"
                      id="userEmail"
                      required
                      onChange={(e) => {
                        removeError("email");
                        set_email(e.target.value);
                      }}
                      value={email}
                      disabled={block_update}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.email ? (
                      <FieldValidation errorMessage={formErrors?.email} />
                    ) : null}
                  </div>
                </div>

                {/* <div className="BLM-radioBoxGroup">
                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="mobile"
                    id="mobile"
                    value="mobile"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "mobile"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="mobile"
                  >
                    mobile
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="online"
                    id="online"
                    value="online"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "online"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="online"
                  >
                    online
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="sms"
                    id="sms"
                    value="sms"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "sms"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="sms"
                  >
                    sms
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="telegram"
                    id="telegram"
                    value="telegram"
                    onChange={(e) => {
                      set_marketing_opt_in(e.target.value);
                    }}
                    checked={marketing_opt_in == "telegram"}
                  />
                  <label
                    className="form-check-label BLM-radioBox"
                    htmlFor="telegram"
                  >
                    telegram
                  </label>
                </div> */}
                <div className="BLM-form-label">Verification Code:</div>
                <div
                  className="BLM-form-control-group"
                  id="regErrorMessageClass"
                >
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="code"
                      placeholder="XXXXXX"
                      // onKeyUp="return numberMobile(event)"
                      // onKeyPress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        removeError("code");

                        set_code(e.target.value);
                      }}
                      value={code}
                    />
                  </div>

                  {runTimer ? (
                    <div className="input-group-append">
                      <span className="input-group-text text-prepend-box">
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </span>
                    </div>
                  ) : (
                    <div
                      className="input-group-append"
                      onClick={() => {
                        sendCode();
                      }}
                    >
                      <span className="input-group-text text-prepend-box">
                        Send Code
                      </span>
                    </div>
                  )}
                </div>
                <div className="BLM-form-errorMsg">
                  {formErrors?.code ? (
                    <FieldValidation errorMessage={formErrors?.code} />
                  ) : null}
                </div>
                <div className="space-10" />
                <button
                  disabled={block_update}
                  className="btn BLM-btnSecondary active BLM-btnLarge"
                  id="disableLoginButtonClick"
                  type="submit"
                >
                  <span className="BLM-btnTxt">Update Profile</span>
                </button>
                <span style={{ color: "#FF4040" }}>
                  {block_update === false
                    ? "You can update your profile information only once. Please ensure all details are correct before submitting"
                    : ""}
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
