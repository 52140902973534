import React, { useState, useEffect } from "react";
import MessageBox from "../../components/Common/MessageBox";
import { useSelector, useDispatch } from "react-redux";
import {
  getLiveFixturesBySportIdCall,
  getLiveSportsCall,
} from "../../helpers/repository";
import { Fixtures } from "../../components/Common/Fixtures";
import { Filters } from "../../components/Common/Filters";
import { LiveFilters } from "../../components/Common/LiveFilters";
import { getSportName,getLiveSportImgName } from "../../components/Common/Utils/helpers";
import {imagePath} from "../../helpers/constants";

function LiveNow(props) {
  const [fixtures, set_fixtures] = useState([]);
  // const [marketChange, setMarketChange] = useState("2");
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const [next_page_url, set_next_page_url] = useState(null);
  const [sportId, setSportId] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [liveInfo, setLiveInfo] = useState([]);
  const [matchTime, setMatchTime] = useState("Today");

  const Sports = useSelector((state) => state?.Sports?.Index?.data ?? []);
  const handleSportCall = (id) => {
    setSportId(id);
  };

  useEffect(() => {
    if (liveInfo.length)
      setSportId(liveInfo?.find((s) => s.live > 0)?.id);
  }, [liveInfo]);

  useEffect(() => {
    getLiveSportsCall().then((res) => {
      let d = res?.data?.data;
      setLiveInfo(d);
    });
  }, []);

  return (
    <>
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <LiveFilters
            sport_id={sportId}
            fixtures={fixtures}
            isForLeague={false}
            setLoadingMessage={setLoadingMessage}
            setLoadMoreMessage={setLoadMoreMessage}
            set_fixtures={set_fixtures}
            setMarketChange={props.setMarketChange}
            getData={getLiveFixturesBySportIdCall}
            url={`/v4/live/sports/${sportId}/fixtures`}
            perPage={35}
            next_page_url={next_page_url}
            set_next_page_url={set_next_page_url}
            trigger={trigger}
            matchTime={matchTime}
            setMatchTime={setMatchTime}
          />
          <div className="BLM-tab-pane" id="LiveNow">
            <div className="BLM-home-livenow">
              <div className="BLM-inplay">
                <div className="BLM-sportsCategoryList-container">
                  <div className="BLM-scrollableArea BLM-horizontal">
                    <div className="BLM-categoryListCollection" id="sportlist">
                      {liveInfo?.map((sport) => {
                       if(sport.live > 0) {
                          return (
                            <>
                              <div
                                id={`${sport?.name}`}
                                className={`BLM-categoryListCollectionItem ${
                                  sportId == sport?.id ? "active" : ""
                                }`}
                                onClick={() => {
                                  handleSportCall(sport?.id);
                                }}
                              >
                                <div className="BLM-categoryListWrapper">
                                  <div className="BLM-icon_container">
                                    {/*<i*/}
                                    {/*  className={`sb-icon_sport-${getSportName(*/}
                                    {/*    sport?.name*/}
                                    {/*  )}`}*/}
                                    {/*/>*/}
                                    <img className='live-sports-img' src={`assets/images/sporticons/${getLiveSportImgName(sport?.name)}.svg`} />

                                    <span
                                      className="BLM-badge BLM-live-count"
                                      id="Spanfootball"
                                    >
                                      {liveInfo?.find((s) => s.id == sport.id)
                                        ?.live ?? 0}
                                    </span>
                                    <span
                                      className="BLM-badge BLM-live-count d-none"
                                      id={`Span${sport?.name}`}
                                    >
                                      LIVE
                                    </span>
                                  </div>
                                  <div
                                    className="BLM-txtWrapper"
                                    name-sportname={`${sport?.name}`}
                                  >
                                    {sport?.name}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                       }
                      })}
                    </div>
                  </div>
                </div>
                <div className="BLM-inplay-content">
                  {fixtures.length ? (
                    <Fixtures
                      marketId={props.marketChange}
                      fixtures={fixtures}
                      pageName={"live"}
                      loadingMessage={loadingMessage}
                      loadMoreMessage={loadMoreMessage}
                      getData={getLiveFixturesBySportIdCall}
                      url={`/v2/live/sports/${sportId}/fixtures`}
                      perPage={35}
                      next_page_url={next_page_url}
                      isLive={true}
                    />
                  ) : (
                    <MessageBox data={loadingMessage} />
                  )}
                </div>
                {next_page_url && (
                  <div
                    onClick={() => {
                      setTrigger(trigger + 1);
                    }}
                  >
                    <MessageBox data={loadMoreMessage} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveNow;
