import axios from "axios";
import { API_URL } from "./constants";
import { getTokenFromLocalStorage } from "./utils";

export const axiosApi = axios.create({
  baseURL: API_URL,
  Authorization: `Bearer ${getTokenFromLocalStorage()}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    common: {
      Accept: "application/json",
    },
    get: {
      "Content-Type": "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
});

axiosApi.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      sessionStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// -----------------------------------------------AUTH-------------------------------------------------
export const postLogin = (data) => {
  return axiosApi.post(`/client/login`, data);
};

export const logoutApiCall = (data) => {
  return axiosApi.post(`/client/logout`, data);
};

export const registerApiCall = (data) => {
  return axiosApi.post(`/client/register`, data);
};

export const updateUserApiCall = (data) => {
  return axiosApi.post(
    `/client/${
      JSON.parse(localStorage.getItem("user"))?.user?.id
    }/update-profile`,
    data
  );
};

// -----------------------------------------------PROMOTIONS-------------------------------------------------
export const getAllPromotionsCall = () => {
  return axiosApi.get(`/client/promotions`);
};

// -----------------------------------------------STATIC PAGES-------------------------------------------------
export const getAllStaticPagesCall = () => {
  return axiosApi.get(`/client/static-pages`);
};

// -----------------------------------------------JACKPOTS-------------------------------------------------
export const getAllJackpotsCall = () => {
  return axiosApi.get(`/client/jackpots`);
};

export const getAllResultJackpotsCall = () => {
  return axiosApi.get(`/client/jackpots-result`);
};

export const getJackpotByIdCall = (id, perPage, marketId) => {
  var param = `?market_id=${marketId}`;

  if (perPage != 0) {
    param += `&page=${perPage + 1}`;
  }

  return axiosApi.get(`/client/jackpots/${id}${param}`);
};

export const getJackpotResultByIdCall = (id, perPage) => {
  var param = ``;

  if (perPage != 0) {
    param += `&page=${perPage + 1}`;
  }

  return axiosApi.get(`/client/view-jackpot-result/${id}${param}`);
};

// -----------------------------------------------LEAGUES-------------------------------------------------
export const getAllLeaguesCall = () => {
  return axiosApi.get(`/v4/leagues?perPage=1000`);
};

// -----------------------------------------------LOCATIONS-------------------------------------------------
export const getAllLocationsCall = () => {
  return axiosApi.get(`/v4/locations`);
};

// -----------------------------------------------SPORTS-------------------------------------------------
export const getAllSportsCall = () => {
  return axiosApi.get(`/v4/sports`);
};

// -----------------------------------------------FIXTURES-------------------------------------------------
export const getFixturesBySportIdCall = (
  url,
  matchTime = "all",
  marketChange = "1",
  perPage = "",
  queryChar = "?",
  day = "today",
  leagueIds = ""
) => {
  return axiosApi.get(
    `${url}${queryChar}match_time=${matchTime}&market_id=${marketChange}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=${perPage}&day=${day}&leagueIds=${leagueIds}`
  );
};

export const getFixturesByLeagueIdCall = (
  id,
  matchTime = "all",
  marketChange = "1",
  leagueIds = ""
) => {
  return axiosApi.get(
    `/v4/leagues/${id}/fixtures?match_time=${matchTime}&market_id=${marketChange}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=30&leagueIds=${leagueIds}`
  );
};
export const getFixtureResultCall = (sportID, matchTime, day) => {
  return axiosApi.get(
    `/v4/fixtures/result?sport_id=${sportID}&match_time=${matchTime}&day=${day}`
  );
};

export const getFixtureByIdCall = (id) => {
  return axiosApi.get(`/v4/fixtures/${id}/show`);
};

export const getLiveFixtureByIdCall = (id) => {
  return axiosApi.get(`/v4/live/fixtures/${id}/show`);
};

// -----------------------------------------------LIVE FIXTURES-------------------------------------------------
export const getLiveFixturesBySportIdCall = (
  url = "",
  matchTime = "all",
  marketChange = "1",
  perPage = ""
) => {
  let queryChar = "?";
  if (url.includes("?")) {
    queryChar = "&";
  }
  return axiosApi.get(
    `${url}${queryChar}match_time=${matchTime}&market_id=${marketChange}&perPage=${perPage}`
  );
};

export const getLiveFixturesByLeagueIdCall = (
  id,
  matchTime = "all",
  marketChange = "1"
) => {
  return axiosApi.get(
    `/v4/live/leagues/${id}/fixtures?match_time=${matchTime}&market_id=${marketChange}`
  );
};

// -----------------------------------------------LIVE SPORTS-------------------------------------------------
export const getLiveSportsCall = () => {
  return axiosApi.get(`/v4/live/info`);
};

export const getLiveSportCall = (id) => {
  return axiosApi.get(`/v4/live/info/${id}`);
};

export const getAllLeaguesBySport = (id, sc, ec) => {
  return axiosApi
    .get(`/v4/sports/${id}/leagues?perPage=5000`)
    .then((res) => {
      sc(res?.data?.data ?? []);
    })
    .catch((e) => {});
};

export const getRegulatoryAndParameters = (sb) => {
  return axiosApi.get(`/v2/regulatory-betting-limits`).then((res) => {
    sb(res.data);
  });
};
export const getCashBalanceLogs = (sb) => {
  // return axiosApi.get(`/admin/regulatory-betting-limits`).then((res) => {
  //   sb(res.data);
  // });
};

// -----------------------------------------------SEARCH FIXTURES-------------------------------------------------
export const searchFixture = (q = "", active_tab, marketChange, matchTime) => {
  return axiosApi.get(
    `/v4/search?q=${q}&active_tab=${active_tab}&match_time=${matchTime}&market_id=${marketChange}&timezone_offset=${new Date().getTimezoneOffset()}`
  );
};

// -----------------------------------------------GET USER DETAILS-------------------------------------------------
export const getUserDetails = () => {
  return axiosApi.get(`/client/get-user-details`);
};

// -----------------------------------------------Place Bet-------------------------------------------------
export const placeBetCall = (data, sc, ec) => {
  // const cancelTokenSource = axios.CancelToken.source();
  return axiosApi
    .post(`/client/place-bet`, data)
    .then((res) => {
      sc(res);
      // cancelTokenSource.cancel();
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
      // cancelTokenSource.cancel();
    });
};
// -----------------------------------------------Book Bet-------------------------------------------------
export const bookBetCall = (data, sc, ec) => {
  return axiosApi
    .post(`/client/book-bet`, data)
    .then((res) => {
      sc(res);
      // cancelTokenSource.cancel();
    })
    .catch((e) => {
      console.log(e?.response?.data?.message);
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
      // cancelTokenSource.cancel();
    });
};

// -----------------------------------------------Forgot password-------------------------------------------------
export const forgotPasswordCall = (data, sc, ec) => {
  return axiosApi
    .post(`/client/forgot-password`, data)
    .then((res) => {
      sc(res);
    })
    .catch((error) => {
      ec(error);
    });
};

// -----------------------------------------------Change Pin-------------------------------------------------
export const changePinCall = (data, sc, ec) => {
  return axiosApi
    .post(`/client/change-pin`, data)
    .then((res) => {
      sc(res);
    })
    .catch((error) => {
      ec(error);
    });
};

// -----------------------------------------------BANNERS-------------------------------------------------
export const getAllBannersCall = () => {
  return axiosApi.get(`/client/banners`);
};

export const BlockedGames = () => {
  return axiosApi.get(`/v4/blockedGames`);
};

// -----------------------------------------------Games-------------------------------------------------

export const getGamesCall = (game, sb) => {
  if (game == "crashx") {
    game = "crashfootball";
  }
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const userId = JSON.parse(localStorage.getItem("user"))?.user?.id;

  return axiosApi
    .get(`/turbo-games/${game}?isloggedin=${isLoggedIn}&userId=${userId}`)
    .then((res) => {
      sb(res?.data);
    });
};

// -----------------------------------------------Casino-------------------------------------------------
export const getAllCasinoGames = (sc, ec) => {
  return axiosApi
    .get(`/v4/casino-games`)
    .then((res) => {
      sc(res.data.data);
    })
    .catch((e) => {
      ec();
    });
};

export const getGameUrl = (id, sc, ec) => {
  return axiosApi
    .get(`/pragmatic-play/get-game-url/${id}`)
    .then((res) => {
      sc(res.data);
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};

export const getUserInfo = (sc, ec) => {
  return axiosApi("/client/info")
    .then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res?.data));
      sc(res?.data);
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};

export const getLiveSports = (sc, ec) => {
  return axiosApi("/v4/live/sports")
    .then((res) => {
      sc(res.data.data.data);
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};

export const getMarketsBySportId = (id, sc, ec) => {
  return axiosApi(`/v4/sports/${id}/markets`)
    .then((res) => {
      sc(res.data.data);
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};

export const getMyBetsData = (category, type, sc, ec, sortType = "latest") => {
  return axiosApi(
    `/client/my-bets/${category.toLowerCase()}/${type.toLowerCase()}/${sortType.toLowerCase()}`
  )
    .then((res) => {
      sc(res.data.data);
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};
// -----------------------------------------------Deposite Amount-------------------------------------------------
export const callDepositeAmount = (data) => {
  return axiosApi.post(`/client/deposite-amount`, data);
};
// -----------------------------------------------Withdraw Amount-------------------------------------------------
export const callWithdrawAmount = (data) => {
  return axiosApi.post(`/client/withdraw-amount`, data);
};
// -----------------------------------------------Affiliate Earning-------------------------------------------------

export const callAffiliateEarning = (id) => {
  return axiosApi.get(`/client/get-raf-earning/${id}`);
};
// -----------------------------------------------Header Menu-------------------------------------------------
export const HeaderMenu = () => {
  return axiosApi.get(`/v4/header-menu`);
};

//-----------------------------------------------Slotegrator games-------------------------------------------------
export const getSlotegratorGameCall = (game, sb) => {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const userId = JSON.parse(localStorage.getItem("user"))?.user?.id;
  const requestData = {
    isloggedin: isLoggedIn,
    userId: userId,
    game: game,
  };
  if (isLoggedIn) {
    return axiosApi
      .post(`/slotegrator/get-game-url`, requestData)
      .then((res) => {
        sb(res?.data);
      });
  } else {
    return axiosApi
      .post(`/slotegrator/get-game-url-demo`, requestData)
      .then((res) => {
        sb(res?.data);
      });
  }
};

//--------------------------------------------------------search slotegrator games--------------------------------
export const searchGames = (id, q = "") => {
  return axiosApi.get(
    `/slotegrator/slotegrator-games-search/${id}?search=${q}`
  );
};

// -----------------------------------------------SEARCH casino games-------------------------------------------------
export const casinosearchGames = (id, q = "") => {
  return axiosApi.get(`/v4/casino-games-search/${id}?search=${q}`);
};
