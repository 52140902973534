import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiError } from "../../store/actions";
import FieldValidation from "../../components/Common/FieldValidation";
import { registerUser } from "../../store/Auth/actions";
import { showMessage } from "../../components/Common/Toaster";
import { axiosApi } from "../../helpers/repository";

function Register(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  const [phone_number, set_phone_number] = useState("");
  const [password, set_password] = useState("");
  const [code, set_code] = useState(null);
  const [confirm_password, set_confirm__password] = useState("");
  const [confirm_password_error, set_confirm_password_error] = useState("");
  const [is_over_18, set_is_over_18] = useState(false);
  const [is_over_18_error, set_is_over_18_error] = useState(false);
  const [allValid, setAllValid] = useState(true);
  const [verify_mobile_number, set_verify_mobile_number] = useState("");
  const [pin, setPin] = useState(null);
  const [sendCodeMessage, setSendCodeMessage] = useState("Send Code");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [referral_source, setReferral_source] = useState("");
  const [referralParam, setReferralParam] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  const PageTypes = useSelector((state) => state?.staticPages.Index ?? [])
    .map((sp) => sp["page_type"])
    .filter((pt) => ["Terms and Conditions", "Privacy Policy"].includes(pt));

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const refParam = url.searchParams.get("ref_source");
    if (refParam !== null) {
      setReferralParam(refParam);
      setReferral_source(refParam);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      phone_number: phone_number,
      password: password,
      confirm_password: confirm_password,
      pin: pin,
      code: parseInt(code),
      ref_id: params.get("ref"),
      referral_source: referral_source,
    };
    if (data["password"] !== data["confirm_password"]) {
      setAllValid(false);
      set_confirm_password_error(
        "Your password is not matched please try again"
      );
      return;
    }
    if (!is_over_18) {
      set_is_over_18_error("You have to be 18+ years and above to register");
      setAllValid(false);
      return;
    }
    setAllValid(false);
    dispatch(registerUser(data, props.history, cb));
  };
  const cb = (message) => {
    set_phone_number("");
    set_password("");
    set_confirm__password("");
    props.history.push("/login");
    showMessage("success", message);
  };

  const sendCode = () => {
    setSendCodeMessage("Sent");
    setRunTimer((t) => !t);
    const data = {
      phone_number: phone_number,
      type: "registration",
    };

    axiosApi({
      method: "post",
      url: `/client/verify-code`,
      data: data,
    })
      .then(function (response) {
        // console.log(response?.data?.pin)
        setPin(response?.data?.pin);
        setSendCodeMessage("Send Code");
      })
      .catch(function (response) {
        set_verify_mobile_number(response?.response?.data?.error);
      });
  };

  const formErrors = useSelector((state) => state?.CommonState);

  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };

  $("#regMobile").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "");
    }
    if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
      this.value = this.value.replace(
        /[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
    }
  });
  return (
    <>
      <div className="BLM-pageHeader">
        <div className="BLM-pageHeader-title">Join Now</div>
      </div>
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    <span style={{ color: "red" }}>*</span>Enter Mobile Number:
                  </div>
                  <div
                    className="BLM-form-control-group"
                    id="regErrorMessageClass"
                  >
                    <div className="BLM-form-addon">+255</div>
                    <div className="BLM-form-control">
                      <input
                        type="tel"
                        id="regMobile"
                        placeholder="XXXXXXXXX"
                        maxLength={9}
                        required
                        onChange={(e) => {
                          removeError("phone_number");
                          setAllValid(true);
                          set_phone_number(e.target.value);
                        }}
                        value={phone_number}
                      />
                    </div>
                    {runTimer ? (
                      <div className="input-group-append">
                        <span className="input-group-text text-prepend-box">
                          <span className="spinner-grow spinner-grow-sm"></span>
                        </span>
                      </div>
                    ) : (
                      <div
                        className="input-group-append"
                        onClick={() => {
                          sendCode();
                        }}
                      >
                        <span className="input-group-text text-prepend-box">
                          Send Code
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.phone_number ? (
                      <FieldValidation
                        errorMessage={formErrors?.phone_number}
                      />
                    ) : null}

                    {verify_mobile_number ? (
                      <FieldValidation errorMessage={verify_mobile_number} />
                    ) : null}
                  </div>
                  <div className="BLM-form-errorMsg d-none">
                    <span id="regLengthErrorMessage" />
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    <span style={{ color: "red" }}>*</span>Verification Code:
                  </div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regPinErrorMessageClass"
                  >
                    <input
                      type="text"
                      id="code"
                      // placeholder="XXXXXX"
                      // onKeyUp="return numberMobile(event)"
                      // onKeyPress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        removeError("code");
                        setAllValid(true);
                        set_code(e.target.value);
                      }}
                      value={code}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.code ? (
                      <FieldValidation errorMessage={formErrors?.code} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    <span style={{ color: "red" }}>*</span>Enter 6 digit PIN:
                  </div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regPinErrorMessageClass"
                  >
                    <input
                      type="password"
                      id="regPass"
                      placeholder="XXXXXX"
                      onkeyup="return numberMobile(event)"
                      onkeypress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        removeError("password");
                        setAllValid(true);
                        set_password(e.target.value);
                      }}
                      value={password}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.password ? (
                      <FieldValidation errorMessage={formErrors?.password} />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">
                    <span style={{ color: "red" }}>*</span>Confirm 6 digit PIN:
                  </div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regConfirmErrorMessageClass"
                  >
                    <input
                      type="password"
                      id="regConfirm"
                      placeholder="XXXXXX"
                      onkeyup="return numberMobile(event)"
                      onkeypress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        set_confirm_password_error("");
                        set_confirm__password(e.target.value);
                      }}
                      value={confirm_password}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {confirm_password_error !== "" ? (
                      <FieldValidation errorMessage={confirm_password_error} />
                    ) : null}
                  </div>
                </div>
                {!referralParam ? (
                  <div className="BLM-form-group">
                    <div className="BLM-form-label">
                      <span style={{ color: "red" }}>*</span>How Did You Hear
                      About Us?
                    </div>
                    <div className="BLM-form-control">
                      <div class="BLM-select">
                        <select
                          id="referral_source"
                          onChange={(e) => {
                            setReferral_source(e.target.value);
                          }}
                          value={referral_source}
                        >
                          <option value="">Select Option</option>
                          <option value="Google">Google</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Twitter">Twitter</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Friend">Friend</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="BLM-form-errorMsg">
                      {formErrors?.referral_source ? (
                        <FieldValidation
                          errorMessage={formErrors?.referral_source}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {/* <div className="BLM-signUpMore">
                  <div
                    className="BLM-accordion collapsed"
                    id="addRegisterCollapse"
                  >
                    <div
                      className="BLM-accordion-header BLM-arrowAfter"
                      onClick={() => {
                        window.signupAccordianCall();
                      }}
                    >
                      More
                    </div>
                    <div className="BLM-accordion-content">
                      <div className="BLM-form-group">
                        <div className="BLM-form-label">
                          Refer a Friend / Keyword:
                        </div>
                        <div className="BLM-form-control">
                          <input
                            type="text"
                            id="regCoupanCode"
                            placeholder="Refer a Friend / Keyword?"
                            required
                          />
                        </div>
                        <div className="BLM-form-errorMsg" />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="BLM-form-group">
                  <div className="BLM-checkbox">
                    <input
                      id="navSubMenuCheck1"
                      name
                      defaultValue="check1"
                      type="checkbox"
                      onChange={() => {
                        set_is_over_18(!is_over_18);
                        setAllValid(true);
                      }}
                    />
                    <label htmlFor="navSubMenuCheck1" />
                    <span className="BLM-checkboxLabel">
                      <span style={{ color: "red" }}>*</span>
                      <b>I am over 18</b>
                      {![undefined, null, ""].includes(PageTypes?.[0]) ? (
                        <>
                          {`- I accept the `}
                          <Link to={`${PageTypes?.[0].replaceAll(" ", "-")}`}>
                            <u>
                              {![undefined, null, ""].includes(PageTypes?.[0])
                                ? PageTypes?.[0]
                                : null}
                            </u>
                          </Link>
                        </>
                      ) : null}
                      {![undefined, null, ""].includes(PageTypes?.[1]) ? (
                        <>
                          {` and `}
                          <Link to={`${PageTypes?.[1].replaceAll(" ", "-")}`}>
                            <u>
                              {![undefined, null, ""].includes(PageTypes?.[1])
                                ? PageTypes?.[1]
                                : null}
                            </u>
                          </Link>
                        </>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="BLM-form-errorMsg">
                  {is_over_18_error !== "" ? (
                    <FieldValidation errorMessage={is_over_18_error} />
                  ) : null}
                </div>
                <div className="BLM-form-errorMsg">
                  {formErrors?.ref_id ? (
                    <FieldValidation errorMessage={formErrors?.ref_id} />
                  ) : null}
                </div>

                <div className="BLM-form-errorMsg">
                  <span id="showErrorMessage" />
                  <span id="showCheckedErrorMessage" />
                  <span id="showApiErrorMessage" />
                </div>
                <button
                  className="btn BLM-btn BLM-btnSecondary active BLM-btnLarge"
                  disabled={!allValid}
                  id="disableRegisterButtonClick"
                  type="submit"
                >
                  <span className="BLM-btnTxt">Join Now</span>
                </button>
              </form>
              <div className="space-10" />
              <div className="BLM-loginRegister-navigation">
                Already Registered?
                <Link to="/login">
                  <span className="BLM-txtHightlight">Login</span>
                </Link>
              </div>
              <div className="space-10" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
