import React, { useState, useEffect } from "react";
import { searchFixture } from "../../helpers/repository";
import ButtonLoader from "./ButtonLoader";
import get from "lodash/get";

function TabMenu(props) {
    const {
        set_fixtures = () => {},

        set_next_page_url,
    } = props;

    const [searchValue, setSearchValue] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const handleSubmit = (e,textValue=false) => {
        e.preventDefault();
        setButtonLoader(true);
        var search=searchValue;
        if(textValue){
            search='';
            document.getElementById('txtSearch').value = ''
        }
        searchFixture(search, props.active_tab,props.marketChange,props.matchTime)
            .then((response) => {
                set_fixtures(response?.data?.data?.data);
                set_next_page_url(null);
            })
            .catch((err) => {
                console.log("no data found");
            })
            .finally(() => {
                setButtonLoader(false);
            });
    };
    return (
      <>
        <div className="BLM-tabs">
          <div className="BLM-search d-none">
            <form onSubmit={handleSubmit}>
              <div className="BLM-serachBox">
                <div className="BLM-form-control">
                  <input
                    type="text"
                    id="txtSearch"
                    placeholder="Search for Team Names or Match ID"
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only alphabetic characters, digits, and spaces
                      const regex = /^[a-zA-Z0-9 ]*$/;
                      if (regex.test(value)) {
                        setSearchValue(value);
                      }
                    }}
                    value={searchValue}
                  />
                </div>
                <button
                  className="BLM-searchbtn"
                  id="disableSearchButtonClick"
                  type="submit"
                >
                  {buttonLoader ? (
                    <ButtonLoader />
                  ) : (
                    <i className="sb-icon_search" />
                  )}
                </button>
                <div
                  className="BLM-searchBtnClose"
                  onClick={(e) => {
                    setSearchValue("");
                    window.disableSearch();
                    handleSubmit(e, true);
                  }}
                >
                  <i className="sb-icon_close" />
                </div>
              </div>
            </form>
          </div>
          <ul className="tabList">
            {props?.tabs?.map((tab) => (
              <>
                <li className={`${tab?.activeClass}`}>
                  <div
                    id={`${tab?.id}`}
                    onClick={(event) => {
                      props?.dispatch(props?.set_active_tab(tab?.id));
                      // props?.dispatch(
                      //   props?.setActiveFooterTab(props?.active_tab)
                      // );
                    }}
                  >
                    {`${tab?.title}`}
                    <span
                      className="BLM-highlight"
                      id={`${tab?.tabHighlightId}`}
                    >
                      {`${tab?.tabHighlightText}`}
                    </span>
                  </div>
                </li>
              </>
            ))}

            {props?.showSearch && (
              <li
                className="BLM-searchButton"
                onClick={() => {
                  window.enableSearch();
                }}
              >
                <div className="BLM-iconSvg">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 24 24"
                    style={{ enableBackground: "new 0 0 24 24" }}
                    xmlSpace="preserve"
                  >
                    <path
                      d="M16.6,10.2c0-1.8-0.6-3.3-1.9-4.6s-2.8-1.9-4.6-1.9S6.9,4.3,5.6,5.6s-1.9,2.8-1.9,4.6s0.6,3.3,1.9,4.6s2.8,1.9,4.6,1.9
										s3.3-0.6,4.6-1.9S16.6,11.9,16.6,10.2z M24,22.2c0,0.5-0.2,0.9-0.5,1.3S22.7,24,22.2,24c-0.5,0-1-0.2-1.3-0.5l-4.9-4.9
										c-1.7,1.2-3.6,1.8-5.8,1.8c-1.4,0-2.7-0.3-3.9-0.8S3.9,18.3,3,17.3s-1.6-2-2.2-3.2S0,11.5,0,10.2s0.3-2.7,0.8-3.9S2.1,3.9,3,3
										s2-1.6,3.2-2.2S8.8,0,10.2,0s2.7,0.3,3.9,0.8s2.3,1.3,3.2,2.2s1.6,2,2.2,3.2s0.8,2.6,0.8,3.9c0,2.1-0.6,4-1.8,5.8l4.9,4.9
										C23.8,21.2,24,21.6,24,22.2z"
                    />
                  </svg>
                </div>
              </li>
            )}
          </ul>
        </div>
      </>
    );
}

export default TabMenu;
