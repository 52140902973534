import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  axiosApi,
  getSlotegratorGameCall,
  searchGames,
} from "../../helpers/repository";
import Iframe from "react-iframe";
import ReactModal from "react-modal";
import ButtonLoader from "../../components/Common/ButtonLoader";
import MessageBox from "../../components/Common/MessageBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SERVER_URL } from "../../helpers/constants";

const ProviderGames = () => {
  const { providerId } = useParams();
  const [games, setGames] = useState([]);
  const [popularGames, setPopularGames] = useState([]);
  const [providerName, setProviderName] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [iframeURL, setIframeURL] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [gameSearchTypes, setGameSearchTypes] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  useEffect(() => {
    let isMounted = true;

    const fetchGames = async () => {
      try {
        const response = await axiosApi.get(`slotegrator/AllGames`);
        if (isMounted) {
          const filteredGames = response.data.data.filter(
            (game) => game.provider_id == providerId
          );
          setGames(filteredGames);
          setLoadingMessage(null);
        }
      } catch (error) {
        console.error("Error fetching provider games:", error);
      }
    };

    const fetchPopularGames = async () => {
      try {
        const response = await axiosApi.get(
          `slotegrator/popular-games/${providerId}`
        );
        if (isMounted) {
          setPopularGames(response.data.data || []);
          setLoadingMessage(null);
        }
      } catch (error) {
        console.error("Error fetching popular games:", error);
      }
    };

    const fetchProviderName = async () => {
      try {
        const response = await axiosApi.get("slotegrator/game-provider");
        if (isMounted) {
          const provider = response.data.data.find((p) => p.id == providerId);
          setProviderName(provider ? provider.name : "Unknown Provider");
        }
      } catch (error) {
        console.error("Error fetching provider name:", error);
      }
    };

    fetchGames();
    fetchPopularGames();
    fetchProviderName();

    return () => {
      isMounted = false;
    };
  }, [providerId]);

  const toggleAccordion = () => {
    setIsCollapsed(!isCollapsed);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "67%",
      overflow: "hidden",
      padding: "20px",
    },
  };

  const handleGameClick = (game) => {
    getSlotegratorGameCall(
      game,
      (res) => setIframeURL(decodeURIComponent(res)),
      (error) => console.error(error)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      setSearchResult(false);
      return;
    }

    setSearchResult(true);
    setButtonLoader(true);

    searchGames(providerId, searchValue)
      .then((response) => {
        setGameSearchTypes(response?.data?.data || []);
      })
      .catch((err) => {
        console.log("No data found");
        setGameSearchTypes([]); 
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  const handleClearClick = () => {
    setSearchValue("");
    setSearchResult(false);
  };
  const history = useHistory();

  const handleBack = () => {
    // Navigate to the /bigwins URL
    history.push("/bigwins");
  };

  return (
    <div className="BLM-commonPage">
      <div className="BLM-commonPage-content BLM-common">
        <div className="container">
          <div
            className="accordion-button mt-3"
            style={{
              backgroundColor: "#2b2e83",
              color: "white",
              cursor: "pointer",
              display: "inline-block",
              position: "relative",
            }}
          >
            {providerName}
            <button
              onClick={handleBack}
              style={{
                backgroundColor: "white",
                color: "#2b2e83",
                border: "none",
                padding: "2px 8px",
                fontSize: "12px",
                cursor: "pointer",
                marginLeft: "5px",
                borderRadius: "4px",
                position: "absolute",
                right: "10px",
                height: "24px",
                lineHeight: "20px",
              }}
            >
              Back
            </button>
          </div>

          <div className="BLM-search mt-3" style={{ backgroundColor: "white" }}>
            <form onSubmit={handleSubmit}>
              <div
                className="BLM-serachBox"
                style={{ backgroundColor: "#2b2e83" }}
              >
                <div className="BLM-form-control">
                  <input
                    type="text"
                    id="txtSearch"
                    placeholder="Search for Game Name"
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only alphabetic characters, digits, and spaces
                      const regex = /^[a-zA-Z0-9 ]*$/;
                      if (regex.test(value)) {
                        setSearchValue(value);
                      }
                    }}
                    value={searchValue}
                  />
                </div>
                {searchValue && (
                  <div
                    className="BLM-searchbtn"
                    id="disableSearchButtonClick"
                    onClick={handleClearClick}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    x
                  </div>
                )}
                <button
                  className="BLM-searchbtn"
                  style={{ backgroundColor: "#f39200" }}
                  id="disableSearchButtonClick"
                  type="submit"
                >
                  {buttonLoader ? (
                    <ButtonLoader />
                  ) : (
                    <i className="sb-icon_search" />
                  )}
                </button>
              </div>
            </form>
          </div>

          {searchResult ? (
            <>
              {gameSearchTypes.length > 0 ? (
                <div className="accordion mt-3">
                  <div
                    className="accordion-button"
                    onClick={toggleAccordion}
                    style={{
                      backgroundColor: "#2b2e83",
                      color: "white",
                      cursor: "pointer",
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    Search Results
                  </div>
                  <div>
                    <div
                      className="row img_row m-0"
                      style={{
                        backgroundColor: "#2b2e83",
                      }}
                    >
                      {gameSearchTypes.map((game) => (
                        <div
                          className="col-lg-3 col-md-4 col-6 mt-3 mb-3"
                          key={game.id}
                        >
                          <img
                            src={`${SERVER_URL}/slotegrator/slotegrator-games/${game.uuid}.png`}
                            // src={`/assets/slotegrator/slotegrator-games/${game.uuid}.png`}
                            alt={game.name}
                            style={{ borderRadius: "10px" }}
                            onClick={() => handleGameClick(game.uuid)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="accordion-button mt-3"
                  style={{
                    backgroundColor: "#2b2e83",
                    color: "white",
                    cursor: "pointer",
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  No Found result.
                </div>
              )}
            </>
          ) : !loadingMessage ? (
            <>
              <div className="accordion mt-3">
                <div
                  className="accordion-button"
                  onClick={toggleAccordion}
                  style={{
                    backgroundColor: "#2b2e83",
                    color: "white",
                    borderColor: "#00137f",
                    paddingLeft: "20px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  Popular Games
                  <span
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className={
                        isCollapsed ? "bi bi-chevron-down" : "bi bi-chevron-up"
                      }
                      viewBox="0 0 16 16"
                      style={{ background: "none" }}
                    >
                      <path
                        fillRule="evenodd"
                        d={
                          isCollapsed
                            ? "M7.646 11.354a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8 10.293l-3.646-3.647a.5.5 0 1 0-.708.708l3.5 3.5z"
                            : "M7.646 4.646a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1-.708.708L8 5.707 4.354 9.354a.5.5 0 1 1-.708-.708l3.5-3.5z"
                        }
                      />
                    </svg>
                  </span>
                </div>
                <div className={`collapse ${isCollapsed ? "" : "show"}`}>
                  <div
                    className="row img_row m-0"
                    style={{
                      backgroundColor: "#2b2e83",
                      // padding: "2px",
                      // cursor: "pointer",
                    }}
                  >
                    {popularGames.map((game) => (
                      <div
                        className="col-lg-3 col-md-4 col-6 mb-3"
                        key={game.id}
                      >
                        <img
                          style={{ borderRadius: "10px" }}
                          src={`${SERVER_URL}/slotegrator/slotegrator-games/${game.uuid}.png`}
                          // src={`/assets/slotegrator/slotegrator-games/${game.uuid}.png`}
                          alt={game.name}
                          onClick={() => handleGameClick(game)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="accordion mt-3">
                <div
                  className="accordion-button"
                  style={{
                    backgroundColor: "#2b2e83",
                    color: "white",
                    borderColor: "#00137f",
                    paddingLeft: "20px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  All Games
                </div>
                <div>
                  <div
                    className="row img_row m-0"
                    style={{
                      backgroundColor: "#2b2e83",
                    }}
                  >
                    {games.map((game) => (
                      <div
                        className="col-lg-3 col-md-4 col-6 mb-3"
                        key={game.id}
                      >
                        <img
                          src={`${SERVER_URL}/slotegrator/slotegrator-games/${game.uuid}.png`}
                          // src={`/assets/slotegrator/slotegrator-games/${game.uuid}.png`}
                          alt={game.name}
                          style={{ borderRadius: "10px" }}
                          onClick={() => handleGameClick(game.uuid)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <MessageBox data={loadingMessage} />
          )}
        </div>
      </div>

      <ReactModal
        style={customStyles}
        isOpen={!!iframeURL}
        onRequestClose={() => setIframeURL(null)}
        className="Casino_Games"
      >
        <Iframe
          url={iframeURL}
          className="myClassname casino_game_iframe"
          display="initial"
          position="relative"
        />
      </ReactModal>
    </div>
  );
};

export default ProviderGames;
