import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../../store/Auth/actions";
import { useSelector, useDispatch } from "react-redux";
import FieldValidation from "../../components/Common/FieldValidation";
import { apiError } from "../../store/actions";
import { messaging, getToken, onMessage } from "../../helpers/firebase";
import ReactModal from "react-modal";
// import { initializeApp } from "firebase/app";

function Login(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiError(""));
  }, []);
  $("#userMobile").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "");
    }
    if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
      this.value = this.value.replace(
        /[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
    }
  });
  const [phone_number, set_phone_number] = useState("");
  const [password, set_password] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [fcm_token, set_fcm_token] = useState(null);
  const [notificationBlocked, setNotificationBlocked] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(10);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 500);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100px!important",
      // backgroundColor: "#272727",
      color: "black",
      padding: "0px",
    },
  };
  useEffect(() => {
    console.log("testing");
    // Register the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log(
            "Service Worker registration successful with scope: ",
            registration.scope
          );
        })
        .catch(function (err) {
          console.log("Service Worker registration failed: ", err);
        });
    }
    checkPermission();
  }, []);
  const checkPermission = async () => {
    const permission = Notification.permission;
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BOi6g1O2SgYYDO6o__p5S_zWXpXB4Kyu9S1_hSZ2znVBB4P7p39jERt1RmivIDUTx_9aAT6uHJ-kqpgAEJGw8us",
        });
        set_fcm_token(token);
        console.log(token);
      } catch (error) {
        console.error("Error getting token:", error);
      }
    } else if (permission === "default") {
      setShowPermissionModal(true);
    } else if (permission === "denied") {
      console.log("Notifications are blocked.");
      setShowPermissionModal(true);
    }
    return true;
  };

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BOi6g1O2SgYYDO6o__p5S_zWXpXB4Kyu9S1_hSZ2znVBB4P7p39jERt1RmivIDUTx_9aAT6uHJ-kqpgAEJGw8us",
        });

        set_fcm_token(token);
      } else {
        console.log("Notification permission denied.");
      }
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
    return true;
  };

  const handleAllowNotifications = () => {
    setShowPermissionModal(false);
    requestPermission();
  };

  const handleDenyNotifications = () => {
    setShowPermissionModal(false);
    console.log("Notification permission denied.");
  };
  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRunTimer((t) => !t);
    // Check permission and await its result

    const data = {
      phone_number: phone_number,
      password: password,
      fcm_token: fcm_token,
    };
    dispatch(loginUser(data, props.history));
  };

  const formErrors = useSelector((state) => state?.CommonState);
  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };

  return (
    <>
      <div className="BLM-pageHeader">
        <div className="BLM-pageHeader-title">Login</div>
      </div>
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister BLM-login">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Phone Number:</div>
                  <div
                    className="BLM-form-control-group"
                    id="errorMessageClass"
                  >
                    <div className="BLM-form-addon">+255</div>
                    <div className="BLM-form-control">
                      <input
                        type="tel"
                        id="userMobile"
                        placeholder="XXXXXXXXX"
                        maxLength={9}
                        required
                        onChange={(e) => {
                          if ([undefined, null, ""].includes(phone_number)) {
                            removeError("phone_number");
                          }
                          set_phone_number(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.phone_number ? (
                      <FieldValidation
                        errorMessage={formErrors?.phone_number}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter 6 digit PIN:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="errorMessagePassClass"
                  >
                    <input
                      type="password"
                      id="regPass"
                      placeholder="XXXXXX"
                      onkeyup="return numberMobile(event)"
                      onkeypress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if ([undefined, null, ""].includes(password)) {
                          removeError("password");
                        }
                        set_password(e.target.value);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.password ? (
                      <FieldValidation errorMessage={formErrors?.password} />
                    ) : null}
                    {formErrors?.message ? (
                      <FieldValidation errorMessage={formErrors?.message} />
                    ) : null}
                  </div>
                </div>
                <div className="space-5" />
                {runTimer ? (
                  <div className="input-group-append">
                    <span
                      className="input-group-text text-prepend-box"
                      style={{ justifyContent: "center" }}
                    >
                      <span className="spinner-grow spinner-grow-sm"></span>
                    </span>
                  </div>
                ) : (
                  <button
                    className="btn BLM-btnSecondary active BLM-btnLarge"
                    id="disableLoginButtonClick"
                    onclick="return gotoLogin();"
                    type="submit"
                  >
                    <span className="BLM-btnTxt">Login</span>
                  </button>
                )}
              </form>
              <div className="space-5" />
              <Link to="/forgot-pin">
                <div className="BLM-forgotPin">
                  <span>Existing customer/forgot pin ?</span>
                </div>
              </Link>
              <div className="space-10" />
              <div className="BLM-loginRegister-navigation">
                Not Registered?
                <Link to="/register">
                  <span className="BLM-txtHightlight">Join Now!</span>
                </Link>
              </div>
              <div className="space-10" />
              <div className="BLM-matchBoxSEO d-none">
                <div className="BLM-matchBoxSEO-header">
                  <h1>Login to your Sakabet Account</h1>
                </div>
                <div className="BLM-matchBoxSEO-content">
                  Log in to your sports betting account here. For premium sports
                  betting that is safe and secure, Sakabet is the place to be!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row img_row"> */}
      <ReactModal
        isOpen={showPermissionModal}
        onRequestClose={handleDenyNotifications}
        style={customModalStyles}
        // className="modal-content-react"
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">
              <h2>Notification Permission</h2>
            </div>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setShowPermissionModal(false);
              }}
            ></button> */}
          </div>
          <div className="modal-body">
            <p style={{ fontSize: "17px" }}>
              This website would like to send you notifications. Allow?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ padding: "8px 17px" }}
              onClick={() => {
                handleAllowNotifications();
              }}
            >
              Allow
            </button>
            <button
              type="button"
              className="btn btn-danger"
              style={{ padding: "8px 17px" }}
              onClick={() => {
                handleDenyNotifications();
              }}
            >
              Deny
            </button>
          </div>
        </div>
      </ReactModal>
      {/* </div> */}
      {/* <ReactModal
        isOpen={showPermissionModal}
        onRequestClose={handleDenyNotifications}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px!important",
            backgroundColor: "grey",
            color: "black",
            padding: "0px",
          },
        }}
      >
        <h2>Allow Notifications</h2>
        <p>This website would like to send you notifications. Allow?</p>
        <button onClick={handleAllowNotifications}>Allow</button>
        <button onClick={handleDenyNotifications}>Deny</button>
      </ReactModal> */}
    </>
  );
}

export default Login;
