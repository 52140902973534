import React, { useState, useEffect } from "react";
import OpenBets from "./OpenBets";
import SettledBets from "./SettledBets";
import PageHeader from "../../components/Common/PageHeader";
import TabMenu from "../../components/Common/TabMenu";
import {BlockedGames, getMyBetsData} from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import { useSelector, useDispatch } from "react-redux";
import { setActiveFooterTab, setActiveTab } from "../../store/actions";
import { isEmpty } from "lodash";
import MessageBox from "../../components/Common/MessageBox";
import {getNoDataMessage} from "../../helpers/utils";

function MyBets(props) {
  const dispatch = useDispatch();

  const [category, set_category] = useState("Sports");
  const [sortCategory, set_sort_category] = useState("Latest");
  const [show, setShow] = useState(false);

  const [myBets, setMyBets] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading Data...");

  const active_tab = useSelector(
    (state) => state?.CommonState?.activeTab ? (state?.CommonState?.activeTab=="MyBets" ? "Open" : state?.CommonState?.activeTab) : "Open"
  );
  useEffect(() => {
    dispatch(setActiveTab("Open"));
    dispatch(setActiveFooterTab("my-bets"));
  }, []);
  useEffect(() => {
    if (!isEmpty(active_tab)) {
      setLoadingMessage("Loading Data...");
      getMyBetsData(category, active_tab , successGetMyBets, errorGetMyBets, sortCategory);
    }
  }, [category, active_tab, sortCategory]);

  const successGetMyBets = (data) => {
    if (data?.length == 0)
      setLoadingMessage("No Data Available");
    setMyBets(data);
  };
  const errorGetMyBets = (error) => {
    showMessage("error", error);
  };

  useEffect(() => {
    if (myBets.length > 0) {
      setLoadingMessage(null);
    } else {
      setLoadingMessage("No Data Available");
    }

    BlockedGames().then((res) => {
      setIframeURL(res);
    }).catch((e) => {
      if(e?.response?.data?.code=="restrict"){
        setShow(true)
        // props.history.push("/comming-soon");
      }
    });

  }, [myBets]);

  const tabs = [
    {
      id: "Open",
      title: "Open",
      activeClass: active_tab == "Open" ? "active" : "",
      tabHighlightText: "",
      tabHighlightId: "",
    },
    {
      id: "Settled",
      title: "Settled Bets",
      activeClass: active_tab == "Settled" ? "active" : "",
      tabHighlightText: ``,
      tabHighlightId: "",
    },
  ];
  return (
    <>
      <PageHeader title={`${active_tab} Bets`} showBack={false} />
      <TabMenu
        tabs={tabs}
        active_tab={active_tab}
        set_active_tab={setActiveTab}
        dispatch={dispatch}
      />

      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content-full-width BLM-common">
          <div className="BLM-tabs-content">
            <div className="BLM-filterDropdowns">
              <div className="BLM-select">
                <select
                  id="ddn_mybets"
                  onChange={(e) => {
                    set_category(e.target.value);
                  }}
                >
                  <option value="Sports" selected={category == "Sports"}>
                    Sports
                  </option>
                  <option value="Virtuals" selected={category == "Virtuals"}>
                    Virtuals
                  </option>
                  {show ? (
                    ""
                  ) : (
                    <option value="Games" selected={category == "Games"}>
                      Games
                    </option>
                  )}
                  <option value="Jackpots" selected={category == "Jackpots"}>
                    Jackpots
                  </option>
                  <option value="Casino" selected={category == "Casino"}>
                    Casino
                  </option>
                  <option value="Bigwins" selected={category == "Bigwins"}>
                    Bigwins
                  </option>
                </select>
              </div>
              <div className="BLM-select">
                <select
                  id="ddn_mybets_sort"
                  onChange={(e) => {
                    set_sort_category(e.target.value);
                  }}
                >
                  <option value="Latest" selected={sortCategory == "Latest"}>
                    Latest
                  </option>
                  <option value="Oldest" selected={sortCategory == "Oldest"}>
                    Oldest
                  </option>
                </select>
              </div>
            </div>
            {active_tab == "Open" &&
              (![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
              ) : (
                <OpenBets
                  myBets={myBets}
                  isCategory={category == "Sports" ? true : false}
                />
              ))}

            {active_tab == "Settled" &&
              (![undefined, null, ""].includes(loadingMessage) ? (
                <MessageBox data={loadingMessage} />
              ) : myBets?.length ? (
                <SettledBets myBets={myBets} category={category} />
              ) : (
                <MessageBox data={`No Data Available`} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBets;
