import React, {useEffect, useState} from "react";
import Iframe from "react-iframe";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import {BlockedGames, getAllCasinoGames, getGamesCall} from "../../helpers/repository";

function Games(props) {
    const isLoggedIn = sessionStorage.getItem("token") ? true : false;
    const [iframeURL, setIframeURL] = useState(null);
    const [show, setShow] = useState(false);
    const demoText = isLoggedIn==true?"Play":"Demo";
    const games = ['CrashX_Football_4_3.png','Dice_4_3.jpeg','Mines_4_3.jpeg','Towers_4_3.jpg','TurboMines_4x3.png','TurboPlinko_4x3.png','Aero_4x3.png'];
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "67%!important",
            overflow: "hidden",
            padding: "20px 20px 20px 20px",
        },
    };


    const _openGameUrl = (game) => {
        $('.ReactModal__Overlay').show();
        game=game.split('.')[0];
        game=game.split('_')[0];
        game=game.toLowerCase();
        getGamesCall(game, successGetGameUrl, errorGetGameUrl);
    };

    useEffect(() => {
        BlockedGames().then((res) => {
            setIframeURL(res);
        }).catch((e) => {
            if(e?.response?.data?.code=="restrict"){
                setShow(true)
                // props.history.push("/comming-soon");
            }
        });
    }, []);

    const successGetGameUrl = (res) => {
        setIframeURL(res);
    };

    const errorGetGameUrl = (error) => {
        showMessage("error", error);
    };
    return (
        <>
            <div className="BLM-commonPage" style={{"background-color":"white"}}>
                <div className="BLM-commonPage-content BLM-common" style={{padding:"0px 22px 22px 22px", marginBottom:"unset"}}>
                    {(show) ? <div style={{textAlign:'center', padding:'88px'}}><h5>No Data Available</h5></div>
                        :  <div className="row img_row img_row1">
                            <ReactModal
                                // style={customStyles}
                                isOpen={iframeURL}
                                onRequestClose={() => setIframeURL(null)}
                                className="Turbo_game"
                            >
                                <Iframe
                                    url={iframeURL}
                                    width="450px"
                                    height="685px"
                                    id="myId"
                                    className="myClassname"
                                    display="initial"
                                    position="relative"
                                />
                            </ReactModal>
                            {games?.map((game, i) => (
                                <div
                                    className={`col-lg-6 col-6 mt-2 position-relative ${(i%2)!=0 ? 'image-padding-set'  : ""}`}
                                >
                                    <img className={'games_turbo'}
                                         style={{"width": "100%","height": "100%", "padding-top":"15px"}}
                                         src={`/assets/images/gameicons/${game}`}

                                         onClick={() => {
                                             _openGameUrl(game);
                                         }}
                                    />
                                </div>
                            ))}
                        </div>}

                </div>
            </div>
        </>
    );
}

export default withRouter(Games);
